import styled from 'styled-components'

export const InlineLoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.size || 12}px;
  height: ${props => props.size || 12}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${props => props.thickness || 1}px solid
      ${props => props.theme.blackWhite.white50};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.theme.blackWhite.white50} transparent
      transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const InlineLoader = ({ size, thickness }) => {
  return (
    <InlineLoaderWrapper size={size} thickness={thickness}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </InlineLoaderWrapper>
  )
}
