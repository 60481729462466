import { StrategyCard, StrategyCardRow } from './about-us.styles'
import { ReactComponent as GlowingStarIcon } from 'assets/about-us/glowing-star.svg'
import { ReactComponent as TickIcon } from 'assets/about-us/tick.svg'
import { ReactComponent as GraphIcon } from 'assets/about-us/graph.svg'
import { Col } from 'react-bootstrap'

const StrategyIcon = ({ title }) => {
  if (title === 'Vision') {
    return <GlowingStarIcon className="w-100" alt="Vision" />
  }
  if (title === 'Mission') {
    return <TickIcon className="w-100" alt="Mission" />
  }
  if (title === 'Goal') {
    return <GraphIcon className="w-100" alt="Goal" />
  }
}

const StrategyCards = ({ strategy }) => {
  return (
    <StrategyCardRow>
      <Col className="strategy-column">
        {strategy.map(item => {
          return (
            <StrategyCard key={item.title}>
              <div className={'strategy-icon-wrapper'}>
                <StrategyIcon title={item.title} />
              </div>
              <div className={'strategy-content-wrapper'}>
                <h4>{item.title}</h4>
                <p>{item.subTitle}</p>
              </div>
            </StrategyCard>
          )
        })}
      </Col>
    </StrategyCardRow>
  )
}

export default StrategyCards
