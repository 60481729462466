import {
  FooterDiv,
  FooterTopSection,
  FoooterBottomSection,
  Copyright,
  Line,
} from './footer.styles'
import { useCallback } from 'react'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import {
  SocialLinksAndProducts,
  RecentBlogs,
} from './social-links-and-products'
import { useNavigate } from 'react-router-dom'
import SocialIconsComponent from './social-icons'

const Footer = () => {
  const navigate = useNavigate()

  const navigateToHome = useCallback(() => {
    navigate(`/#home`)
  }, [navigate])

  return (
    <>
      <FooterDiv className="container-fluid">
        <FooterTopSection className="row">
          <div className="col-md-12 col-lg-3 cursor-pointer">
            <div className={'logo-wrapper'} onClick={navigateToHome}>
              <div>
                <Logo className={'logo'} />
              </div>
              <div className={'ps-2'}>
                <div className={'brand-name'}>GRAYPACT</div>
                <div className={'brand-tagline'}>
                  Where Vision Meets Innovation
                </div>
              </div>
            </div>
          </div>
          <SocialLinksAndProducts />
          <RecentBlogs />
          <SocialIconsComponent />
        </FooterTopSection>
        <FoooterBottomSection>
          <Line />
          <Copyright>Copyright © 2024 All Right Reserved</Copyright>
        </FoooterBottomSection>
      </FooterDiv>
    </>
  )
}

export default Footer
