import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { withOpacity } from 'shared/utils'
import { device, deviceWidth } from 'shared/device-breakpoints'

export const BlogsWrapper = styled(Container)`
  position: relative;
  padding: 16px;

  ${device.tablet} {
    padding: 24px;
  }
  ${device.laptop} {
    padding: 24px 100px;
  }
  background-color: ${props => props.theme.blackWhite.white0};
  .blog-links-container {
    background-color: ${props => props.theme.blackWhite.white0};
    border-left: 1px solid
      ${props => withOpacity(props.theme.blackWhite.black200, 20)};
    position: sticky;
    top: 98px;
    padding: 0 8px 0 0;
    height: calc(100vh - 98px);
    .accordion-item {
      border: none;
    }
    .accordion-button {
      background-color: ${props => props.theme.blackWhite.white0};
      box-shadow: none;
    }
  }
  .blogs-content-container {
    .blogs-content {
      background-color: ${props => props.theme.misc.snow};
    }
    flex-grow: 1;
    .blogs-content {
      max-width: ${deviceWidth.xl}px;
      padding: 8px;
      ${device.tablet} {
        padding: 24px;
      }
      ${device.laptop} {
        padding: 48px 72px;
      }
    }
    .blog-section-with-sub-heading {
      scroll-margin-top: 90px;
      .sub-heading {
        margin: 48px 0 24px;
        line-height: 32px;
      }
    }
  }
`
