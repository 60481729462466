import Home from 'pages/home'
import Blog from 'pages/blogs'
import Header from 'sections/header'
import Footer from 'sections/footer/footer'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/blogs/:id" element={<Blog />} />
        <Route path="/blogs" element={<Navigate to={`/#blogs`} replace />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
