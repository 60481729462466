import Validator from 'fastest-validator'
import { useCallback, useState } from 'react'
import * as _ from 'lodash'

const useFormValidator = form => {
  const [errors, setErrors] = useState({})

  const getInstance = useCallback(fields => {
    const v = new Validator()
    const schema = {
      fullName: { type: 'string', min: 3, label: 'Full Name' },
      email: { type: 'email', label: 'Email' },
      message: { type: 'string', min: 3, max: 400, label: 'Message' },
    }
    return v.compile(_.pick(schema, fields))
  }, [])

  const validate = useCallback(
    (fields = Object.keys(form)) => {
      if (_.isEmpty(fields)) return true
      const fieldsToValidate = _.pick(form, fields)

      const check = getInstance(fields)
      const result = check(fieldsToValidate)
      if (result === true) {
        setErrors({})
        return true
      }

      if (Array.isArray(result)) {
        const _errors = _.groupBy(result, 'field')
        setErrors(_errors)
        return false
      }
    },
    [form, getInstance]
  )

  return { validate, errors }
}

export default useFormValidator
