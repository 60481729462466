import PropTypes from 'prop-types'

import { BlogCardContainer } from './blogs.styles'
import { ReactComponent as ExternalLink } from 'assets/images/external-link.svg'
import { ReactComponent as Arrow } from 'assets/images/arrow.svg'
import { Link } from 'react-router-dom'
import BlogsImageComponent from './blogs-image-component'

const BlogCard = ({ blogData }) => {
  return (
    <BlogCardContainer className="blog-card bg-white shadow d-flex flex-column">
      <div className="blog-image-container">
        <BlogsImageComponent slug={blogData.slug} />
      </div>
      <div className="type pt-4 fw-semibold blog-tag">{blogData.type}</div>
      <div className="heading d-flex justify-content-between align-items-center">
        <div className="title py-2 fw-semibold">{blogData.heading}</div>
        <Link to={'/blogs/' + blogData.slug}>
          <ExternalLink />
        </Link>
      </div>
      <div className="content py-2">{blogData.shortDescription}</div>
      <div className="flex-grow-1">
        <div className="h-100 d-flex align-items-end">
          <Link
            to={'/blogs/' + blogData.slug}
            className="read-more d-flex align-items-center gap-2 grow"
          >
            <span className="read-more-text">{blogData.readTime}</span>
            <div className="arrow-icon">
              <Arrow />
            </div>
          </Link>
        </div>
      </div>
    </BlogCardContainer>
  )
}

export default BlogCard

BlogCard.propTypes = {
  blogData: PropTypes.object,
}
