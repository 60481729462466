import styled from 'styled-components'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { withOpacity } from 'shared/utils'
import { ReactComponent as DotFrame } from 'assets/images/dot-frame.svg'
import { ReactComponent as AboutUsVectorBase } from 'assets/about-us/about-us-vector.svg'
import { Row } from 'react-bootstrap'
import { device } from 'shared/device-breakpoints'

export const AboutUsContainer = styled(SectionWrapper)`
  padding-top: 12px;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
  background-color: ${props =>
    withOpacity(props.theme.secondary.secondary500, 10)};

  ${device.tablet} {
    padding: 16px 24px;
    padding-bottom: 50px;
  }
  ${device.laptop} {
    padding-top: 24px;
    padding-left: 100px;
    padding-right: 100px;
  }
  #about-us {
    position: absolute;
    top: -40px;
    left: 0;
  }

  .about-company {
    background-color: ${props => props.theme.blackWhite.white50};
    padding: 42px;
    border-bottom-right-radius: 50px;
    ${device.laptopL} {
      text-align: start;
      .deco-line-container {
        display: flex;
        justify-content: start;
      }
    }
  }

  .about-us-vector-wrapper {
    display: flex;
    place-content: flex-end;
    place-items: flex-end;
    ${device.laptopL} {
      place-content: flex-end;
      place-items: flex-end;
    }
  }
`

export const FiguresRow = styled(Row)`
  margin-top: 70px;
  margin-bottom: 20px;
  .figure-wrapper {
    display: flex;
    flex-direction: column;
  }
`

export const Description1 = styled.p`
  font-size: 18px;
  color: ${props => props.theme.blackWhite.black300};

  strong {
    font-weight: 600;
  }
`

export const Description2 = styled.p`
  font-size: 16px;
  color: ${props => props.theme.blackWhite.black300};
`

export const FigureHeading = styled.p`
  font-size: 22px;
  font-weight: 600;
  color: ${props => props.theme.blackWhite.black300};
  display: inline-block;
  line-height: 38px;
  border-bottom: 3px solid ${props => props.theme.blackWhite.black300};
`

export const FigureText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.blackWhite.black300};
  max-width: 100px;
`

export const DotFrameImage = styled(DotFrame)`
  position: absolute;
  width: 312px;
  top: -210px;
  right: 80px;
  display: none;
  ${device.laptopL} {
    display: block;
    top: -210px;
  }
`

export const AboutUsVector = styled(AboutUsVectorBase)`
  height: 370px;
  height: 400px;
  ${device.tablet} {
    height: 500px;
  }
`

export const StrategyCardRow = styled(Row)`
  .strategy-column {
    display: flex;
    gap: 40px;
    flex-direction: column;
    gap: 10px;
    ${device.laptopL} {
      gap: 40px;
      flex-direction: row;
    }
  }
`
export const StrategyCard = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  padding: 16px;
  height: 100px;
  box-shadow: 0 12px 16px -4px ${props => withOpacity(props.theme.blackWhite.black300, 10)};
  background-color: ${props => props.theme.blackWhite.white50};
  width: 100%;

  ${device.tablet} {
    margin-top: 20px;
  }

  ${device.laptopL} {
    margin-top: 30px;
    max-width: 440px;
  }

  .strategy-icon-wrapper {
    width: 60px;
    display: flex;
    place-items: center;
    svg {
      height: 38px;
    }
    margin-right: 16px;
  }

  .strategy-content-wrapper {
    h4 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
`
