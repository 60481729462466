import content from 'assets/data/contents'
import { FeedbackSectionWrapper } from './feedback.styles'
import { Title } from 'components/atoms/SectionTitle'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { DecoLine } from 'components/atoms/DecoLine'
import { Row } from 'react-bootstrap'
import FeedbackSlider from './feedback-carousel'
import NavigationAnchor from 'components/atoms/navigation-anchor'

const ClientsFeedback = () => {
  const { id, heading } = content.clientsFeedback

  return (
    <FeedbackSectionWrapper>
      <SectionWrapper
        $bgColor={'transparent'}
        className="feedback-upper-section"
      >
        <NavigationAnchor id={id} />

        <Row className={'justify-content-center my-4 mt-5'}>
          <DecoLine />
        </Row>

        <Title className="text-center pb-4 px-5">{heading}</Title>
      </SectionWrapper>

      <div className="position-relative slider-wrapper">
        <SectionWrapper $bgColor={'transparent'} className="pb-5">
          <FeedbackSlider />
        </SectionWrapper>
      </div>
    </FeedbackSectionWrapper>
  )
}

export default ClientsFeedback
