import { ImageWrapper } from '../image-wrapper'
import Angular from 'assets/technologies/technologies/angular.svg'
import Android from 'assets/technologies/technologies/android.png'
import ReactLogo from 'assets/technologies/technologies/react.png'
import Chatbot from 'assets/technologies/technologies/chatbot.png'
import Django from 'assets/technologies/technologies/django.png'
import Flutter from 'assets/technologies/technologies/flutter.png'
import Php from 'assets/technologies/technologies/php.svg'
import MariaDB from 'assets/technologies/technologies/mariadb.png'
import Javascript from 'assets/technologies/technologies/javascript.png'
import MongoDB from 'assets/technologies/technologies/mongodb.png'
import Java from 'assets/technologies/technologies/java.svg'
import MySQL from 'assets/technologies/technologies/mysql.png'
import NextJS from 'assets/technologies/technologies/next-js.png'
import VueJs from 'assets/technologies/technologies/vue.png'
import Swift from 'assets/technologies/technologies/swift.png'
import NLP from 'assets/technologies/technologies/nlp.png'
import IOS from 'assets/technologies/technologies/ios.png'
import Node from 'assets/technologies/technologies/node.svg'
import Postgresql from 'assets/technologies/technologies/psql.png'
import Python from 'assets/technologies/technologies/python.png'
import Pytorch from 'assets/technologies/technologies/pytorch.png'
import ReactNative from 'assets/technologies/technologies/react-native.png'
import TensorFlow from 'assets/technologies/technologies/tensorFlow.png'

const TechnologiesImages = () => {
  return (
    <>
      <ImageWrapper alt={'react'} image={ReactLogo} />
      <ImageWrapper alt={'node'} image={Node} />
      <ImageWrapper alt={'flutter'} image={Flutter} />
      <ImageWrapper alt={'python'} image={Python} />
      <ImageWrapper alt={'mongoDB'} image={MongoDB} />
      <ImageWrapper alt={'java'} image={Java} />
      <ImageWrapper alt={'django'} image={Django} />
      <ImageWrapper alt={'android'} image={Android} />
      <ImageWrapper alt={'javascript'} image={Javascript} />
      <ImageWrapper alt={'php'} image={Php} />
      <ImageWrapper alt={'swift'} image={Swift} />
      <ImageWrapper alt={'pytorch'} image={Pytorch} />
      <ImageWrapper alt={'ios'} image={IOS} />
      <ImageWrapper alt={'angular'} image={Angular} />
      <ImageWrapper alt={'react-native'} image={ReactNative} />
      <ImageWrapper alt={'vue'} image={VueJs} />
      {/*<ImageWrapper alt={'psql'} image={Postgresql} />*/}
      {/*<ImageWrapper alt={'chatbot'} image={Chatbot} />*/}
      {/*<ImageWrapper alt={'mariaDB'} image={MariaDB} />*/}
      {/*<ImageWrapper alt={'mysql'} image={MySQL} />*/}
      {/*<ImageWrapper alt={'nextJS'} image={NextJS} />*/}
      {/*<ImageWrapper alt={'nLP'} image={NLP} />*/}
      {/*<ImageWrapper alt={'pytorch'} image={Pytorch} />*/}
      {/*<ImageWrapper alt={'tensor-flow'} image={TensorFlow} />*/}
    </>
  )
}

export default TechnologiesImages
