import content from 'assets/data/contents'
import { Row, Col } from 'react-bootstrap'
import { Title } from 'components/atoms/SectionTitle'
import {
  ServiceCard,
  ServiceCardTitle,
  ServiceDescription,
  ServiceIconWrapper,
  ServicesSectionWrapper,
} from './services.styles'
import { theme } from 'shared/theme'
import {
  GenAIIcon,
  AutomationIcon,
  ComputeIcon,
  CodeIcon,
  ConsultIcon,
  DesignIcon,
  AnalyticsIcon,
  MigrationIcon,
} from 'assets/services'
import { DecoLine } from '../../components/atoms/DecoLine'
import { withOpacity } from 'shared/utils'
import NavigationAnchor from 'components/atoms/navigation-anchor'

const ServiceIcon = ({ id }) => {
  if (id === 'coding') return <CodeIcon alt="coding" />
  if (id === 'automation') return <AutomationIcon alt="automation" />
  if (id === 'gen-ai') return <GenAIIcon alt="ai" />
  if (id === 'cloud-computing') return <ComputeIcon alt="cloud-computing" />
  if (id === 'it-consulting') return <ConsultIcon alt="it-consulting" />
  if (id === 'design') return <DesignIcon alt="design" />
  if (id === 'analytics') return <AnalyticsIcon alt="analytics" />
  if (id === 'cloud-migration') return <MigrationIcon alt="cloud-migration" />
}

const Services = () => {
  const { cards, id, heading } = content.services

  const listItems = cards.map((data, index) => (
    <Col md={6} lg={3} key={index} className={'service-col'}>
      <ServiceCard className="mb-3 mb-xl-4 p-3 p-xl-4">
        <ServiceIconWrapper className={'service-icon-wrapper'}>
          <ServiceIcon id={data.id} />
        </ServiceIconWrapper>
        <ServiceCardTitle>{data.heading}</ServiceCardTitle>
        <ServiceDescription>{data.description}</ServiceDescription>
      </ServiceCard>
    </Col>
  ))

  return (
    <ServicesSectionWrapper
      $bgColor={withOpacity(theme.primary.primary500, 10)}
      className="container-fluid"
    >
      <NavigationAnchor id={id} />
      <Row className={'justify-content-center mt-5 pb-3'}>
        <DecoLine />
      </Row>
      <Title className="text-center pb-4">{heading}</Title>
      <Row className={'service-row'}>{listItems}</Row>
    </ServicesSectionWrapper>
  )
}

export default Services
