export const theme = {
  blackWhite: {
    white0: '#ffffff',
    white50: '#FAFAFA',
    white100: '#F6F6F6',
    white200: '#EEEEEE',
    white400: '#DDDDDD',
    black0: '#000000',
    black200: '#848484',
    black300: '#424242',
    black400: '#1C1C1C',
  },
  primary: {
    primary50: '#F0F2F8',
    primary500: '#6578BD',
    primary600: '#5C6DAC',
    primary700: '#485586',
  },
  secondary: {
    secondary100: '#CFD4E0',
    secondary200: '#B8C1E1',
    secondary500: '#64739A',
    secondary700: '#47526D',
  },
  misc: {
    snow: '#FAFAFA',
    whiteSmoke: '#F1F1F1',
    bianca: '#FAF5EF',
    solitude: '#E5E8EC',
    lightGrayishBlue: '#CFD5EB',
    error: '#f14f2e',
  },
}
