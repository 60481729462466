import { ImageWrapper } from '../image-wrapper'
import Adobe from 'assets/technologies/tools/adobe.png'
import Figma from 'assets/technologies/tools/figma.png'
import GitBook from 'assets/technologies/tools/gitbook.png'
import GitLab from 'assets/technologies/tools/gitlab.png'
import Jira from 'assets/technologies/tools/jira.png'
import LogRocket from 'assets/technologies/tools/logrocket.png'
import Miro from 'assets/technologies/tools/miro.png'
import Notion from 'assets/technologies/tools/notion.png'
import Postman from 'assets/technologies/tools/postman.png'
import Slack from 'assets/technologies/tools/slack.png'
import Trello from 'assets/technologies/tools/trello.png'

const ToolsImages = () => {
  return (
    <>
      <ImageWrapper image={Adobe} alt={'Adobe'} />
      <ImageWrapper image={Figma} alt={'Figma'} />
      <ImageWrapper image={GitBook} alt={'GitBook'} />
      <ImageWrapper image={GitLab} alt={'GitLab'} />
      <ImageWrapper image={Jira} alt={'Jira'} />
      <ImageWrapper image={LogRocket} alt={'LogRocket'} />
      <ImageWrapper image={Miro} alt={'Miro'} />
      <ImageWrapper image={Notion} alt={'Notion'} />
      <ImageWrapper image={Postman} alt={'Postman'} />
      <ImageWrapper image={Slack} alt={'Slack'} />
      <ImageWrapper image={Trello} alt={'Trello'} />
    </>
  )
}

export default ToolsImages
