import { Col, Row } from 'react-bootstrap'
import content from 'assets/data/contents'
import { Title } from 'components/atoms/SectionTitle'
import ProjectImageComponent from './ProjectImageComponent'
import {
  DotFrameImageOne,
  DotFrameImageTwo,
  DotFrameImageThree,
  TriangleOne,
  TriangleTwo,
  ProjectTile,
  Chips,
  ProjectSectionWrapper,
} from './projects.styles'
import { theme } from 'shared/theme'
import { DecoLine } from '../../components/atoms/DecoLine'
import NavigationAnchor from 'components/atoms/navigation-anchor'

const Projects = () => {
  const titlesData = content.projects.tiles
  const heading = content.projects.heading
  const id = content.projects.id

  const Tiles = titlesData.map((tileData, index) => {
    return (
      <ProjectTile
        id={tileData.id}
        key={tileData.projectName}
        className={`position-relative ${index % 2 !== 0 ? 'flex-row-reverse' : ''}`}
      >
        <Col
          lg={7}
          className="p-0 project-img-wrapper"
          style={{ backgroundColor: theme.misc[`${tileData.bgColor}`] }}
        >
          <ProjectImageComponent projectName={`${tileData.projectName}`} />
        </Col>
        <Col
          lg={5}
          style={{ backgroundColor: theme.misc[`${tileData.bgColor}`] }}
          className="position-relative"
        >
          <div className="p-1 px-md-2 p-xl-4 py-4 d-flex flex-column h-100 content-wrapper">
            <h2 className="fs-4 fw-semibold">{tileData.projectName}</h2>
            <div className="flex-grow-1 pt-2 project-description">
              {tileData.shortDescription.text}
              {tileData.shortDescription.list && (
                <ul>
                  {tileData.shortDescription.list?.map((item, index) => {
                    return <li key={index}>{item}</li>
                  })}
                </ul>
              )}
            </div>
            <div className={'d-flex flex-wrap gap-2'}>
              {tileData.chips.map((chip, index) => {
                return <Chips key={index}>{chip}</Chips>
              })}
            </div>
          </div>
        </Col>
      </ProjectTile>
    )
  })

  return (
    <ProjectSectionWrapper
      $bgColor={theme.blackWhite.white0}
      className="container-fluid position-relative pb-lg-5 overflow-hidden"
    >
      <NavigationAnchor id={id} />
      <DotFrameImageOne />
      <DotFrameImageTwo />
      <TriangleOne />
      <TriangleTwo />
      <Row className={'justify-content-center mt-5'}>
        <DecoLine />
      </Row>
      <Title className={'text-center py-3 pb-4'}>{heading}</Title>
      {Tiles}
      <DotFrameImageThree />
    </ProjectSectionWrapper>
  )
}

export default Projects
