import styled from 'styled-components'

import { ReactComponent as FacebookLogo } from 'assets/images/fb-icon.svg'
import { ReactComponent as Instagram } from 'assets/images/insta.svg'
import { ReactComponent as LinkedIn } from 'assets/images/linkedin.svg'
import { ReactComponent as XLogo } from 'assets/images/x.svg'
import { withOpacity } from 'shared/utils'
import { device } from 'shared/device-breakpoints'
import SectionWrapper from '../../components/atoms/SectionWrapper'
import logoBG from 'assets/images/logo-bg.svg'

export const FooterDiv = styled.div`
  background-color: ${props => props.theme.blackWhite.black300};
  padding: 16px;
  color: ${props => props.theme.blackWhite.white50};
  overflow: hidden;

  ${device.tablet} {
    padding: 32px 24px;
  }
  ${device.laptop} {
    padding: 32px 0;
  }
  .products-list {
    margin-left: 35px;
    ${device.laptop} {
      margin-left: 15px;
    }
  }
  .recent-blogs {
    margin-left: 0;
    ${device.laptop} {
      margin-left: 15px;
    }
  }
  .social-icons {
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    max-width: none;
    ${device.tablet} {
      max-width: 78px;
      grid-template-columns: repeat(2, 1fr);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: ${props => props.theme.blackWhite.white0};
      text-decoration: none;
      font-size: 24px;
    }
  }
`

export const FooterTopSection = styled(SectionWrapper)`
  background-color: ${props => props.theme.blackWhite.black300};
  .logo-wrapper {
    cursor: pointer;
    user-select: none;
    background-image: url(${logoBG});
    display: flex;
    background-size: cover;
    padding: 12px;
    max-width: 200px;
    margin-top: 16px;
    justify-self: center;
    border-radius: 6px;
    background-position: right;

    ${device.laptop} {
      justify-self: start;
      margin-top: 28px;
    }
  }
  .brand-name {
    color: ${props => props.theme.secondary.secondary500};
    font-size: 25px;
    margin-top: -6px;
  }
  .brand-tagline {
    color: ${props => props.theme.secondary.secondary500};
    font-size: 12px;
  }
  svg.logo {
    height: 36px;
    width: auto;
  }
`

export const FacebookIcon = styled(FacebookLogo)`
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.blackWhite.white0};
  border-radius: 17px;
  padding: 10px;
`
export const InstagramIcon = styled(Instagram)`
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.blackWhite.white0};
  border-radius: 17px;
  padding: 10px;
`
export const XLogoIcon = styled(XLogo)`
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.blackWhite.white0};
  border-radius: 17px;
  padding: 10px;
`
export const LinkedInIcon = styled(LinkedIn)`
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.blackWhite.white0};
  border-radius: 17px;
  padding: 10px;
`

export const FoooterBottomSection = styled.div`
  background-color: ${props => props.theme.blackWhite.black300};
  color: ${props => props.theme.blackWhite.white50};
  align-items: center;
`

export const Copyright = styled.p`
  color: ${props => props.theme.blackWhite.white0};
  font-size: 12px;
  text-align: center;
  align-items: center;
  padding-top: 25px;
`

export const Line = styled.div`
  border-bottom: 0.5px solid
    ${props => withOpacity(props.theme.blackWhite.white0, 74)};
  padding-top: 16px;
`

export const LinksHeading = styled.div`
  text-align: left;
  border-bottom: 0.5px solid ${props => props.theme.blackWhite.white100};
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  width: fit-content;
  ${device.laptop} {
    margin-top: 0px;
    width: auto;
  }
`

export const Link = styled.div`
  text-align: left;
  .link-text {
    text-decoration: none;
    color: ${props => props.theme.blackWhite.white0};
    display: inline-block;
    border-bottom: 0.5px solid
      ${props => withOpacity(props.theme.blackWhite.white0, 50)};
    line-height: 1.2;

    &:hover {
      border-bottom-color: ${props =>
        withOpacity(props.theme.blackWhite.white0, 100)};
    }
  }
`
export const RecentBlogsWrapper = styled.div`
  .link-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
`
