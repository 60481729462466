import styled from 'styled-components'
import BackgroundImageBase from 'assets/images/bg.svg'
import { withOpacity } from 'shared/utils'
import { device } from 'shared/device-breakpoints'

export const StyledBlogsCarousel = styled.div`
  .slick-slider {
    ${device.tablet} {
      margin-left: 16px;
      margin-right: 16px;
    }

    ${device.laptop} {
      margin-left: -12px;
      margin-right: -12px;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
      &::before {
        color: red;
      }
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      bottom: -45px;
      align-items: center;
      position: static;
      margin: 24px 0 0;

      .prev-btn,
      .next-btn {
        display: flex;
        cursor: pointer;
        padding: 5px;
        svg {
          transition: filter 0.1s ease-in-out;
        }
        &:hover {
          svg {
            filter: brightness(0.1);
          }
        }
      }
      .next-btn {
        display: flex;
        transform: rotate(180deg);
      }

      ul {
        display: flex;
        margin: 0 30px;
        padding-left: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            display: flex;
            place-content: center;
            width: 13px;
            height: 13px;
            content: '';
            background: ${props => props.theme.blackWhite.white400};
            border-radius: 50%;
            opacity: 1;
            cursor: pointer;
            &:before {
              display: none;
            }
          }

          &.slick-active {
            button {
              background: ${props => props.theme.secondary.secondary500};
            }
          }
        }
      }
    }
    .slick-slide {
      margin-bottom: 20px;
    }
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
`

export const BlogsSectionWrapper = styled.div`
  font-weight: 400;
  border-bottom: 10px solid
    ${props => withOpacity(props.theme.secondary.secondary500, 16)};

  .blogs-upper-section {
    background-color: ${props => props.theme.primary.primary50};
  }
  .blogs-lower-section {
    background-color: transparent;
  }
  .slider-wrapper {
    background-image: url(${BackgroundImageBase});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 150%;

    ${device.tablet} {
      background-size: contain;
    }
  }
`

export const BlogCardContainer = styled.div`
  min-height: 480px;
  margin: 0 16px;
  padding: 24px;
  .blog-image-container {
    min-height: 50px;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover; /* Ensures the image scales properly */
    }
  }

  ${device.tablet} {
    margin: 0 12px;
  }
  .type {
    color: ${props => props.theme.secondary.secondary500};
    font-size: 14px;
  }
  .heading {
    .title {
      color: ${props => props.theme.blackWhite.black300};
      font-size: 18px;
    }
  }
  .content {
    color: ${props => props.theme.blackWhite.black200};
  }
  .read-more {
    text-decoration: none;
    .read-more-text {
      line-height: 2.2;
      font-size: 0.75rem;
      color: ${props => props.theme.blackWhite.black300};
    }
    .arrow-icon {
      cursor: pointer;
      svg {
        transform: rotate(180deg);
        transition: filter 0.1s ease-in-out;
      }
    }
    &:hover {
      .read-more-text {
        color: ${props => props.theme.blackWhite.black0};
      }
      svg {
        filter: brightness(0.1);
      }
    }
  }
`

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: ${props => props.theme.blackWhite.black300};
`
