import { css } from 'styled-components'

export const techImageStyles = css`
  img[alt='chatbot'] {
    width: 125%;
  }
  img[alt='django'] {
    width: 80%;
  }
  img[alt='android'] {
    width: 50%;
  }
  img[alt='javascript'] {
    width: 110%;
  }
  img[alt='flutter'] {
    width: 90%;
  }
  img[alt='php'] {
    width: 80%;
    padding: 12px;
  }
  img[alt='mongoDB'] {
    width: 125%;
  }
  img[alt='java'] {
    width: 125%;
  }
  img[alt='mysql'] {
    width: 80%;
  }
  img[alt='nLP'] {
    width: 80%;
  }
  img[alt='node'] {
    width: 60%;
  }
  img[alt='psql'] {
    width: 50%;
  }
  img[alt='ios'] {
    width: 70%;
  }
  img[alt='vue'] {
    width: 40%;
  }
  img[alt='python'] {
    width: 110%;
  }
  img[alt='pytorch'] {
    width: 90%;
  }
  img[alt='react-native'] {
    width: 90%;
  }
`

export const cloudImageStyles = css`
  img[alt='AWS'] {
    width: 50%;
  }
  img[alt='AlibabaCloud'] {
    width: 80%;
  }
  img[alt='Cloudfare'] {
    width: 110%;
  }
  img[alt='DigitalOcean'] {
    width: 50%;
  }
  img[alt='GCP'] {
    width: 80%;
  }
`

export const aiImageStyles = css`
  img[alt='ChatGPT'] {
    width: 110%;
  }
  img[alt='DallE'] {
    width: 110%;
  }
  img[alt='Gemini'] {
    width: 70%;
  }
  img[alt='Bot'] {
    width: 35%;
  }
  img[alt='LangChain'] {
    width: 60%;
  }
`

export const integrationImageStyles = css`
  img[alt='GooglePlacesAPI'] {
    width: 90%;
  }
  img[alt='Shopify'] {
    width: 80%;
  }
  img[alt='Stripe'] {
    width: 80%;
  }
  img[alt='Zoho'] {
    width: 80%;
  }
`

export const toolsImageStyles = css`
  img[alt='GitLab'] {
    width: 120%;
  }
  img[alt='Jira'] {
    width: 90%;
  }
  img[alt='LogRocket'] {
    width: 110%;
  }
  img[alt='Notion'] {
    width: 65%;
  }
  img[alt='Trello'] {
    width: 90%;
  }
`
