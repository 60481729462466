import { ReactComponent as Talkbiz } from 'assets/projects/talkbiz.svg'
import { ReactComponent as CarSpot } from 'assets/projects/carspot.svg'
import Mixpai from 'assets/projects/mixpai.png'
import { ReactComponent as Sonomic } from 'assets/projects/sonomic.svg'

const ProjectImageComponent = ({ projectName }) => {
  if (projectName === 'Talkbiz') {
    return <Talkbiz className="w-100" alt="Talkbiz" />
  }
  if (projectName === 'Mixpai') {
    return <img src={Mixpai} className="w-100" alt="CarSpot" />
  }
  if (projectName === 'CarSpot') {
    return <CarSpot className="w-100" alt="CarSpot" />
  }
  if (projectName === 'Sonomic') {
    return <Sonomic className="w-100" alt="CarSpot" />
  }
}

export default ProjectImageComponent
