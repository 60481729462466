import { Row, Col } from 'react-bootstrap'
import { Button } from '../../components/atoms/Button'
import {
  ContactUsContainer,
  FormGroup,
  HaveQuestion,
  NiceHearing,
  GroupHeading,
  GroupText,
  GirlWithBinoculars,
  Ellipse2,
  Capsules,
} from './contact-us.styles'
import { Title } from '../../components/atoms/SectionTitle'
import { DecoLine } from '../../components/atoms/DecoLine'
import content from 'assets/data/contents'
import NavigationAnchor from 'components/atoms/navigation-anchor'
import { useEffect, useState } from 'react'
import useFormValidator from './useFormValidator'
import _ from 'lodash'
import { baseUrl } from '../../shared/constants'
import { toast } from 'react-toastify'
import { InlineLoader } from '../../components/atoms/InlineLoader'

const ContactUs = () => {
  const {
    id,
    heading,
    haveAQuestion,
    niceHearing,
    visitUsHeading,
    officeAddress,
    contactUsHeading,
    contact,
  } = content.contactUs
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [touched, setTouched] = useState([])
  const { validate, errors } = useFormValidator(
    { fullName, email, message },
    touched
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => {
    const { name: field, value } = e.target
    if (field === 'fullName') {
      setFullName(value)
    } else if (field === 'email') {
      setEmail(value)
    } else if (field === 'message') {
      setMessage(value)
    }
  }

  const handleBlur = e => {
    const { name: field } = e.target
    setTouched(prev => {
      return _.uniq([...prev, field])
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isValid = validate()
    if (!isValid) return
    const payload = {
      full_name: fullName,
      email,
      message,
    }

    setIsLoading(true)
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText)
        }
        return response.json()
      })
      .then(data => {
        console.log('Success:', data)
        toast('Email sent successfully, we will get back to you soon!')
      })
      .catch(error => {
        console.error('Error:', error)
        toast('Service is temporarily down, please try after sometime.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    validate(touched)
  }, [touched])

  return (
    <ContactUsContainer className="container-fluid">
      {/* For navigation purpose */}
      <NavigationAnchor id={id} />

      <Row>
        <Col md={12} lg={7} className={'position-relative'}>
          <Ellipse2 className="contact-us-images" />
          <Capsules className="contact-us-images" />
          <GirlWithBinoculars className="contact-us-images" />

          <div className={'text-wrapper'}>
            <div className="heading-wrapper">
              <DecoLine />
            </div>
            <Title className={'my-2 contact-us-title'}>{heading.split()}</Title>
            <HaveQuestion>{haveAQuestion}</HaveQuestion>
            <NiceHearing className={'my-3'}>{niceHearing}</NiceHearing>

            <GroupHeading>{visitUsHeading}</GroupHeading>
            <GroupText>{officeAddress}</GroupText>

            <GroupHeading>{contactUsHeading}</GroupHeading>
            <GroupText>{contact.email}</GroupText>
            <GroupText>{contact.phone}</GroupText>
          </div>
        </Col>
        <Col md={12} lg={5}>
          <form className="get-in-touch-form" onSubmit={handleSubmit}>
            <Row>
              <FormGroup md={12}>
                <label htmlFor="full-name">Full Name:</label>
                <input
                  id="full-name"
                  name="fullName"
                  type="text"
                  value={fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors['fullName'] && (
                  <p className="error">{errors['fullName'][0].message}</p>
                )}
              </FormGroup>

              <FormGroup md={12}>
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors['email'] && (
                  <p className="error">{errors['email'][0].message}</p>
                )}
              </FormGroup>

              <FormGroup md={12}>
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors['message'] && (
                  <p className="error">{errors['message'][0].message}</p>
                )}
              </FormGroup>

              <Col className={'d-flex justify-content-end'}>
                <Button disabled={isLoading} type={'submit'} $secondary>
                  {isLoading && <InlineLoader />} Submit
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </ContactUsContainer>
  )
}

export default ContactUs
