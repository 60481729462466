import styled from 'styled-components'
import { withOpacity } from '../../shared/utils'

export const IconButton = styled.button`
  border: 0;
  background: none;
  border-radius: 100%;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -6px;

  &:active {
    background-color: ${props =>
      withOpacity(props.theme.primary.primary500, 10)};

    svg {
      fill: ${props => props.theme.blackWhite.white50};
    }
  }
`
