import content from 'assets/data/contents'
import { Row, Col, Tabs, Tab, Nav } from 'react-bootstrap'
import {
  EllipseOneImage,
  EllipseTwoImage,
  DotFrameImage,
  TabsContainer,
  MainHeading,
  TechnologiesSectionWrapper,
  ArrowImage,
  Title,
} from './technologies.styles'
import { useState } from 'react'
import { theme } from 'shared/theme'
import { withOpacity } from '../../shared/utils'
import ImageContainers, { ImageContainersLG } from './images-containers'
import TabsList from './tab-list'
import NavigationAnchor from 'components/atoms/navigation-anchor'
import { DecoLine } from 'components/atoms/DecoLine'
import useScreenSize from 'shared/hooks/useScreenSize'

const Technologies = () => {
  const [activeKey, setActiveKey] = useState('Technologies')

  const { width } = useScreenSize()
  const { id, heading, subHeading, data } = content.technologies

  const scrollIntoTabView = (selectedTab, e) => {
    const pageX = e.nativeEvent.pageX
    const selectedTabIndex = data.indexOf(selectedTab)
    const nextTab = data[selectedTabIndex + 1]
    const prevTab = data[selectedTabIndex - 1]
    let tab
    if (selectedTabIndex === 0) {
      tab = selectedTab
    } else if (pageX > width / 2) {
      tab = nextTab
    } else {
      tab = prevTab
    }
    if (!tab) return
    document.getElementById(`technology-tab-${tab}`).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }

  return (
    <TechnologiesSectionWrapper
      $bgColor={withOpacity(theme.misc.lightGrayishBlue, 10)}
      className="container-fluid position-relative"
    >
      <NavigationAnchor id={id} />
      <ArrowImage />
      <EllipseOneImage />
      <EllipseTwoImage />
      <DotFrameImage />

      <MainHeading className={'mt-5 mb-4'}>
        <Col className="position-relative d-flex flex-column align-items-center d-lg-block">
          <DecoLine />
          <Title className="fs-lg-1 pt-3 pt-lg-3 text-center text-lg-start">
            {heading}
          </Title>
        </Col>
      </MainHeading>

      <Row className="sub-heading-row position-relative d-lg-inline-block my-1 mb-3">
        <Col lg={6} xxl={5}>
          <div className="text-center text-lg-start sub-heading">
            {subHeading}
          </div>
        </Col>
      </Row>

      {/* Mobile & Tablet */}
      <Row className="mt-4 mt-lg-5 mb-4 px-2 px-md-3 d-lg-none">
        <TabsContainer>
          <Tabs
            id="technology"
            activeKey={activeKey}
            onSelect={(selectedTab, event) => {
              event.stopPropagation()
              setActiveKey(selectedTab)
              scrollIntoTabView(selectedTab, event)
            }}
            className="mb-3 flex-nowrap overflow-x-scroll justify-content-md-between"
          >
            {data.map(heading => {
              return (
                <Tab key={heading} eventKey={heading} title={heading}>
                  <ImageContainers className={'mt-md-1'} heading={heading} />
                </Tab>
              )
            })}
          </Tabs>
        </TabsContainer>
      </Row>

      {/* Laptop and Larger screens */}
      <Row className="mt-lg-5 pb-5 d-none d-lg-flex">
        <Col className={'px-4'}>
          <Tab.Container className={'col'} activeKey={activeKey}>
            <TabsContainer className={'row pb-5'}>
              <Col lg={4} xl={5} xxl={6} className={'px-0'}>
                <Nav variant="pills" className="flex-column">
                  <TabsList
                    activeKey={activeKey}
                    setActiveKey={e => setActiveKey(e)}
                  />
                </Nav>
              </Col>
              <Col lg={8} xl={7} xxl={6} className="pt-3 px-0">
                <Tab.Content>
                  <ImageContainersLG />
                </Tab.Content>
              </Col>
            </TabsContainer>
          </Tab.Container>
        </Col>
      </Row>
    </TechnologiesSectionWrapper>
  )
}

export default Technologies
