import { Row } from 'react-bootstrap'
import styled from 'styled-components'

import { ReactComponent as DotFrame } from 'assets/images/dot-frame.svg'
import { ReactComponent as Triangle } from 'assets/images/triangle.svg'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { device } from '../../shared/device-breakpoints'

export const ProjectSectionWrapper = styled(SectionWrapper)`
  overflow: hidden;
  position: relative;
`

export const DotFrameImageOne = styled(DotFrame)`
  position: absolute;
  z-index: 0;
  transform: scale(0.8);
  opacity: 0.5;
  display: none;

  ${device.tablet} {
    display: unset;
    top: 10px;
    left: 0;
  }

  ${device.laptop} {
    top: 10px;
    left: 151px;
  }
`

export const DotFrameImageTwo = styled(DotFrame)`
  position: absolute;
  top: 1000px;
  left: -190px;
  opacity: 0.5;
  transform: scale(0.7);
  display: none;

  ${device.laptop} {
    display: unset;
  }
`

export const DotFrameImageThree = styled(DotFrame)`
  position: absolute;
  bottom: -200px;
  right: 300px;
  display: none;
  opacity: 0.5;
  transform: scale(0.6);

  ${device.laptop} {
    display: unset;
  }
`

export const TriangleOne = styled(Triangle)`
  position: absolute;
  top: 30px;
  right: 20%;
  transform: scale(1);
  display: none;
  opacity: 0.5;

  ${device.tablet} {
    display: unset;
  }
`

export const TriangleTwo = styled(Triangle)`
  position: absolute;
  top: 80px;
  right: 8%;
  display: none;
  transform: scale(1.2) rotate(-60deg);
  opacity: 0.5;
  ${device.tablet} {
    display: unset;
  }
`

export const ProjectTile = styled(Row)`
  margin-bottom: 1.2rem;
  margin-left: 4px;
  margin-right: 4px;
  scroll-margin-top: 100px;

  ${device.tablet} {
    margin-bottom: 1.6rem;
    margin-left: 16px;
    margin-right: 16px;
  }
  ${device.laptop} {
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.2);

  .project-img-wrapper {
    display: flex;
    overflow: hidden;

    svg,
    img {
      height: auto;
      width: 100%;
      transform: scale(1.01);
    }
  }

  .content-wrapper {
    overflow: auto;

    .project-description {
      overflow: auto;
      margin-bottom: 16px;

      ${device.laptop} {
        font-size: 14px;
      }
      ${device.laptopL} {
        font-size: 18px;
      }

      ul {
        padding-top: 8px;
        font-size: 14px;
      }
    }
  }
`

export const Chips = styled.span`
  font-size: 12px;
  line-height: 30px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  height: 26px;
  padding: 4px 12px;
  color: ${props => props.theme.blackWhite.white50};
  background-color: ${props => props.theme.secondary.secondary500};

  ${device.tablet} {
    font-size: 14px;
  }
  ${device.laptopXL} {
    font-size: 15px;
  }
`
