import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { ReactComponent as Bars } from 'assets/images/bars.svg'
import styled from 'styled-components'
import Offcanvas from 'react-bootstrap/Offcanvas'

const StyledModalWrapper = styled.div`
  .blogs-link-btn {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 45px;
    height: 45px;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    z-index: 10000;
    svg {
      width: 100%;
    }
  }
`

const BlogsLinksModel = ({ children }) => {
  const [show, setShow] = useState(false)

  const toggleModal = event => {
    console.log('handleClose', event)
    setShow(!show)
  }

  const handleClose = event => {
    console.log('handleClose', event)
    setShow(false)
  }

  return (
    <StyledModalWrapper>
      <Button
        variant="light"
        className="blogs-link-btn d-lg-none"
        onClick={toggleModal}
      >
        <Bars />
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement={'end'}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>In this blog</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body onClick={handleClose}>{children}</Offcanvas.Body>
      </Offcanvas>
    </StyledModalWrapper>
  )
}

export default BlogsLinksModel
