import React, { useCallback, useEffect, useState } from 'react'
import { Link, List } from './header.styles'
import { useLocation, useNavigate } from 'react-router-dom'

const NavLinks = ({ navLinks, isMenuOpen, onClick }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isHomePage, setHomePage] = useState(true)

  useEffect(() => {
    setHomePage(location.pathname.indexOf('/blogs/') !== -1)

    if (location.hash) {
      const targetElement = document.getElementById(
        location.hash.replace('#', '')
      )
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location.pathname, location.hash])

  const handleLinkClick = useCallback((event, link) => {
    event.preventDefault()
    onClick?.()

    const targetElement = document.getElementById(link)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
      navigate(`/#${link}`, { replace: true })
    } else {
      navigate(`/#${link}`)
    }
  }, [])

  return navLinks.map((product, index) => (
    <List key={product.title} className={index === 0 ? 'active' : ''}>
      <Link
        href={`${isHomePage ? '/' : ''}#${product.link}`}
        onClick={event => handleLinkClick(event, product.link)}
        className={`nav-link ${isMenuOpen ? 'mobile-nav-link' : ''}`}
      >
        {product.title}
      </Link>
    </List>
  ))
}

export default NavLinks
