export const useScrolling = () => {
  function enableScrolling() {
    document.body.style.overflow = 'auto'
  }

  function disableScrolling() {
    document.body.style.overflow = 'hidden'
  }

  return [enableScrolling, disableScrolling]
}
