import { Row, Col, Button } from 'react-bootstrap'
import { BlogsWrapper } from './bogs.styles'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLayoutEffect } from 'react'
import content from 'assets/data/contents'

import { GenerativeAI } from './blogs-pages/generative-ai'
import { CloudComputing } from './blogs-pages/cloud-computing'
import { DataAnalytics } from './blogs-pages/data-analytics'
import { AICyberSecurity } from './blogs-pages/ai-cybersecurity'
import { FutureITInfraStructure } from './blogs-pages/it-infrastructure'
import { BlogsInfoPanel } from './blogs-links-component/index'
import BlogsLinksModel from './blogs-links-model'
import SimpleSlider from 'sections/blogs/carousel'

const BlogContainer = () => {
  const { pathname } = useLocation()
  const [currentBlog, setCurrentBlog] = useState('')
  const [blogData, setBlogData] = useState('')

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])

  useEffect(() => {
    setCurrentBlog(pathname.split('/')[2])
    const data = content.blogs.blogsList.find(e => e.slug === currentBlog)
    setBlogData(data)
  }, [currentBlog, pathname])

  return (
    <>
      <BlogsWrapper fluid>
        <Row className="sample">
          <Col md={12} lg={8} xl={9}>
            {currentBlog === 'generative-ai' ? (
              <GenerativeAI blogData={blogData} />
            ) : null}
            {currentBlog === 'cloud-computing' ? (
              <CloudComputing blogData={blogData} />
            ) : null}

            {currentBlog === 'data-analytics' ? (
              <DataAnalytics blogData={blogData} />
            ) : null}
            {currentBlog === 'ai-cyber-security' ? (
              <AICyberSecurity blogData={blogData} />
            ) : null}
            {currentBlog === 'it-infrastructure' ? (
              <FutureITInfraStructure blogData={blogData} />
            ) : null}
          </Col>
          <Col lg={4} xl={3} className="d-none d-lg-block">
            <div className="blog-links-container">
              <div className="ps-3 pb-3 fs-5 fw-medium d-none d-lg-block">
                In this blog
              </div>
              <BlogsInfoPanel navLinks={blogData?.navLinks} />
            </div>
          </Col>
          <BlogsLinksModel>
            <BlogsInfoPanel navLinks={blogData?.navLinks} />
          </BlogsLinksModel>
        </Row>
        <Row className="mt-5 mb-3">
          <Col>
            <div className="pb-3 fs-5 fw-medium d-none d-lg-block">
              Recent blogs
            </div>
            <SimpleSlider />
          </Col>
        </Row>
      </BlogsWrapper>
    </>
  )
}

export default BlogContainer
