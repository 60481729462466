import { ImageWrapper } from '../image-wrapper'
import AWS from 'assets/technologies/cloud-services/aws.png'
import Azure from 'assets/technologies/cloud-services/microsoft-azure.png'
import AlibabaCloud from 'assets/technologies/cloud-services/alibaba-cloud.png'
import Cloudfare from 'assets/technologies/cloud-services/cloudflare.png'
import DigitalOcean from 'assets/technologies/cloud-services/digitalocean.png'
import GCP from 'assets/technologies/cloud-services/gcp.png'

const CloudServicesImages = () => {
  return (
    <>
      <ImageWrapper alt={'AWS'} image={AWS} />
      <ImageWrapper alt={'Azure'} image={Azure} />
      <ImageWrapper alt={'AlibabaCloud'} image={AlibabaCloud} />
      <ImageWrapper alt={'Cloudfare'} image={Cloudfare} />
      <ImageWrapper alt={'DigitalOcean'} image={DigitalOcean} />
      <ImageWrapper alt={'GCP'} image={GCP} />
    </>
  )
}

export default CloudServicesImages
