export const ImageWrapper = ({ image, alt, className }) => {
  return (
    <div className="wrapper-box col-4 col-md-3 col-lg-3 pb-">
      <div className={`image-wrapper w-100 h-100 p-3 p-md-4 p-lg-4`}>
        {typeof image === 'string' ? (
          <img className={`${className}`} src={image} alt={alt} />
        ) : (
          <image />
        )}
      </div>
    </div>
  )
}
