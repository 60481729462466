import content from 'assets/data/contents'
import Tab from 'react-bootstrap/Tab'
import { BrandImagesContainer } from '../technologies.styles'
import TechnologiesImages from './technologies-images'
import CloudServicesImages from './cloud-servces-images'
import AIIntegrationsImages from './ai-integrations-images'
import IntegrationsImages from './integrations-images'
import ToolsImages from './tools-images'

const ImageContainers = ({ heading, className }) => {
  return (
    <BrandImagesContainer className={`row g-2 g-md-3 ${className}`}>
      {heading === 'Technologies' ? <TechnologiesImages /> : ''}
      {heading === 'Cloud Services' ? <CloudServicesImages /> : ''}
      {heading === 'AI Integration' ? <AIIntegrationsImages /> : ''}
      {heading === 'Integrations' ? <IntegrationsImages /> : ''}
      {heading === 'Tools' ? <ToolsImages /> : ''}
    </BrandImagesContainer>
  )
}

export const ImageContainersLG = () => {
  const { data } = content.technologies
  return data.map(heading => {
    return (
      <Tab.Pane eventKey={heading} key={heading}>
        <ImageContainers heading={heading} />
      </Tab.Pane>
    )
  })
}

export default ImageContainers
