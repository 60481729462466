import {
  FacebookIcon,
  InstagramIcon,
  XLogoIcon,
  LinkedInIcon,
} from './footer.styles'

const SocialIconsComponent = () => {
  const iconsList = ['facebook', 'instagram', 'twitter', 'linkedin']
  const socialIcons = iconsList.map(e => {
    if (e === 'facebook') {
      return (
        <a key={e} href="https://www.facebook.com">
          <FacebookIcon />
        </a>
      )
    } else if (e === 'instagram') {
      return (
        <a key={e} href="https://www.instagram.com">
          <InstagramIcon />
        </a>
      )
    } else if (e === 'twitter') {
      return (
        <a key={e} href="https://www.x.com">
          <XLogoIcon />
        </a>
      )
    } else if (e === 'linkedin') {
      return (
        <a key={e} href="https://www.linkedin.com">
          <LinkedInIcon />
        </a>
      )
    } else {
      return <></>
    }
  })

  return (
    <div className="col-md-2 col-lg-2 d-flex justify-content-center justify-content-lg-end align-items-start">
      <div className="social-icons">{socialIcons}</div>
    </div>
  )
}

export default SocialIconsComponent
