import { ImageWrapper } from '../image-wrapper'
import ChatGPT from 'assets/technologies/ai-integrations/chatgpt.png'
import DallE from 'assets/technologies/ai-integrations/dall-e.png'
import Gemini from 'assets/technologies/ai-integrations/gemini.png'
import ElevenLabs from 'assets/technologies/ai-integrations/eleven-labs.png'
import LangChain from 'assets/technologies/ai-integrations/langchain.png'
import Bot from 'assets/technologies/ai-integrations/bot.png'
import Descript from 'assets/technologies/ai-integrations/descript.png'

const AIIntegrationsImages = () => {
  return (
    <>
      <ImageWrapper image={ChatGPT} alt={'ChatGPT'} />
      <ImageWrapper image={DallE} alt={'DallE'} />
      <ImageWrapper image={Gemini} alt={'Gemini'} />
      <ImageWrapper image={ElevenLabs} alt={'ElevenLabs'} />
      <ImageWrapper image={Descript} alt={'Descript'} />
      <ImageWrapper image={Bot} alt={'Bot'} />
      <ImageWrapper image={LangChain} alt={'LangChain'} />
    </>
  )
}

export default AIIntegrationsImages
