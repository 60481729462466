import {
  AboutUsContainer,
  AboutUsVector,
  Description1,
  Description2,
  DotFrameImage,
  FigureHeading,
  FiguresRow,
  FigureText,
} from './about-us.styles'
import content from 'assets/data/contents'
import { Row, Col } from 'react-bootstrap'
import { DecoLine } from 'components/atoms/DecoLine'
import { Title } from 'components/atoms/SectionTitle'
import { Button } from 'components/atoms/Button'
import StrategyCards from './strategy-cards'
import NavigationAnchor from 'components/atoms/navigation-anchor'

const AboutUs = () => {
  const { id, heading, description, figures, buttonText, strategy } =
    content.aboutUs
  return (
    <AboutUsContainer className="container-fluid">
      {/* For navigation purpose */}
      <NavigationAnchor id={id} />
      <DotFrameImage />
      <Row>
        <Col md={12} xl={6}>
          <div className={'about-company'}>
            <div className="deco-line-container">
              <DecoLine />
            </div>
            <Title className={'my-2'}>{heading}</Title>
            <Description1 className={'my-2'}>
              At <strong>Graypact</strong>, we are passionate about transforming
              businesses through innovative technology solutions.
            </Description1>
            <Description2 className={'my-4'}>{description}</Description2>
            <FiguresRow>
              {figures.map(item => {
                return (
                  <Col className="figure-wrapper" key={item.title}>
                    <div className="d-flex justify-content-start">
                      <FigureHeading>{item.figure}</FigureHeading>
                    </div>
                    <FigureText className={'mt-2 text-start'}>
                      {item.title}
                    </FigureText>
                  </Col>
                )
              })}
            </FiguresRow>
            <Button $secondary>{buttonText}</Button>
          </div>
        </Col>
        <Col
          md={12}
          xl={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div className={'about-us-vector-wrapper'}>
            <AboutUsVector />
          </div>
        </Col>
      </Row>
      <StrategyCards strategy={strategy} />
    </AboutUsContainer>
  )
}

export default AboutUs
