import content from 'assets/data/contents'
import { Link } from 'react-router-dom'
import {
  LinksHeading,
  Link as LinkDiv,
  RecentBlogsWrapper,
} from './footer.styles'

const LinksWrapper = ({ heading, links }) => {
  return (
    <div
      className={`d-flex flex-column ${heading === 'Products' ? 'products-list' : ''} ${heading === 'Recent Blogs' ? 'recent-blogs' : ''}`}
    >
      <LinksHeading>{heading}</LinksHeading>
      {links.map((item, index) => {
        return (
          <LinkDiv key={index}>
            <Link to={`/${item.id}`} className="link-text">
              {item.name}
            </Link>
          </LinkDiv>
        )
      })}
    </div>
  )
}

const SocialLinksAndProducts = () => {
  const { quickLinks } = content.footer
  const { tiles } = content.projects

  const formattedProjectTiles = tiles.map(e => ({
    name: e.projectName,
    id: `#${e.id}`,
  }))

  return (
    <div className="col-md-6 col-lg-4">
      <div className="row">
        <div className="col-md-6 col-sm-6 col-6">
          <LinksWrapper heading={'Quick Links'} links={quickLinks.values} />
        </div>
        <div className="col-md-6 col-sm-6 col-6">
          <LinksWrapper
            className="products-list"
            heading={'Products'}
            links={formattedProjectTiles}
          />
        </div>
      </div>
    </div>
  )
}

const RecentBlogs = () => {
  const { blogsList } = content.blogs

  const formattedBlogsTiles = blogsList.map(e => ({
    name: e.heading,
    id: `blogs/${e.slug}`,
  }))

  return (
    <RecentBlogsWrapper className="col-md-3">
      <LinksWrapper heading={'Recent Blogs'} links={formattedBlogsTiles} />
    </RecentBlogsWrapper>
  )
}

export { SocialLinksAndProducts, RecentBlogs }
