import styled from 'styled-components'
import SectionWrapper from '../../components/atoms/SectionWrapper'
import Col from 'react-bootstrap/Col'
import { ReactComponent as GirlWithBinocularsBase } from 'assets/images/girl-with-binoculars.svg'
import { ReactComponent as Ellipse2Base } from 'assets/images/ellipse2.svg'
import { ReactComponent as CapsulesDownBase } from 'assets/images/capsules-down.svg'
import { device } from 'shared/device-breakpoints'

export const ContactUsContainer = styled(SectionWrapper)`
  padding-top: 70px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;

  .text-wrapper {
    position: relative;
  }
  .heading-wrapper {
    display: flex;
    justify-content: center;
    ${device.laptop} {
      justify-content: start;
    }
  }
  .contact-us-title {
    display: flex;
    justify-content: center;
    ${device.laptop} {
      justify-content: start;
    }
  }

  ${device.laptop} {
    padding-left: 100px;
    padding-right: 100px;
  }
  .get-in-touch-form {
    padding-top: 40px;
    ${device.laptop} {
      padding-top: 0px;
      display: block;
    }
  }

  .contact-us-images {
    display: none;
    ${device.laptopL} {
      display: block;
    }
  }
`

export const FormGroup = styled(Col)`
  margin-bottom: 16px;

  label {
    display: block;
    color: ${props => props.theme.blackWhite.black300};
    font-size: 16px;
  }

  input,
  textarea {
    display: block;
    height: 45px;
    width: 100%;
    border-radius: 6px;
    padding: 12px;
    background-color: ${props => props.theme.blackWhite.white100};
    border: 1px solid ${props => props.theme.blackWhite.white200};
  }

  textarea {
    max-height: 215px;
    min-height: 215px;
  }

  .error {
    color: ${props => props.theme.misc.error};
    font-size: 12px;
    margin-top: 4px;
  }
`

export const NiceHearing = styled.p`
  font-size: 16px;
  color: ${props => props.theme.primary.primary500};
`

export const HaveQuestion = styled.p`
  font-size: 18px;
  color: ${props => props.theme.blackWhite.black300};
`

export const GroupHeading = styled.p`
  font-size: 22px;
  font-weight: 500;
  color: ${props => props.theme.blackWhite.black300};
  margin-top: 32px;
`

export const GroupText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.blackWhite.black200};
`

export const Ellipse2 = styled(Ellipse2Base)`
  position: absolute;
  bottom: -300px;
  left: 16px;
  width: 500px;
  height: 500px;
  z-index: 0;
  opacity: 0.9;
`

export const GirlWithBinoculars = styled(GirlWithBinocularsBase)`
  position: absolute;
  bottom: -150px;
  left: 200px;
  transform: scale(0.7);
`

export const Capsules = styled(CapsulesDownBase)`
  position: absolute;
  bottom: -150px;
  left: 180px;
  transform: scale(0.7);
`
