import styled from 'styled-components'
import { device } from '../../shared/device-breakpoints'

const Title = styled.p`
  font-weight: 600;
  font-size: 26px;
  font-style: normal;
  color: ${props => props.theme.blackWhite.black300};

  ${device.tablet} {
    font-size: 30px;
  }
`

export { Title }
