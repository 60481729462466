import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  scroll-margin-top: 70px;
`

const NavigationAnchor = ({ id }) => {
  return <Container id={id} />
}

export default NavigationAnchor

NavigationAnchor.propTyes = {
  id: PropTypes.string,
}
