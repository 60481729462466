import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { device } from 'shared/device-breakpoints'

export const ServicesSectionWrapper = styled(SectionWrapper)`
  overflow: hidden;
  position: relative;

  .service-row {
    margin-left: 2px;
    margin-right: 2px;

    ${device.tablet} {
      margin-bottom: 1rem;
    }

    ${device.laptop} {
      margin-bottom: 2rem;
      margin-left: calc(0px - var(--bs-gutter-x) * 0.5);
      margin-right: calc(0px - var(--bs-gutter-x) * 0.5);
    }

    .service-col {
      padding: 0;
      ${device.tablet} {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
  }
`

export const ServiceCard = styled(Card)`
  background: ${props => props.theme.blackWhite.white0};
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  min-height: 300px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    color: ${props => props.theme.blackWhite.white0};
    background-color: ${props => props.theme.primary.primary500};
    transform: scale(1.03);

    .service-icon-wrapper {
      border: 2px solid ${props => props.theme.blackWhite.white50};
    }

    svg {
      filter: brightness(3);
    }
  }
`

export const ServiceCardTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  padding: 16px 0;
  text-align: center;
`

export const ServiceDescription = styled.div`
  text-align: center;
  display: block;
  position: relative;
  overflow-y: auto;

  ${device.tablet} {
    height: 120px;
  }

  ${device.laptop} {
    height: 150px;
  }
`

export const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  padding: 10px;
  border: 2px solid ${props => props.theme.secondary.secondary500};
`
