import styled from 'styled-components'
import { withOpacity } from 'shared/utils'

export const BlogLinksContainer = styled.div`
  height: calc(100vh - 145px);
  overflow-y: auto;
  position: relative;

  // Scrollbar start

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: ${props =>
      withOpacity(props.theme.blackWhite.black200, 5)};
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${props =>
      withOpacity(props.theme.blackWhite.black200, 20)};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${props =>
      withOpacity(props.theme.blackWhite.black200, 20)};
    border: 1px solid
      ${props => withOpacity(props.theme.blackWhite.black200, 20)};
  }

  // Scrollbar end

  ul {
    list-style: none;
    padding: 0px;
    li {
      line-height: 18px;
      margin-bottom: 4px;

      a {
        padding-left: 4px;
        font-size: 0.9rem;
        text-decoration: none;
        color: #424242;
        padding: 4px 4px 4px 15px;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
      }
      a.active {
        padding-left: 11px;
        border-left: 4px solid ${props => props.theme.primary.primary500};
        color: ${props => props.theme.primary.primary500};
        font-weight: 500;
      }
    }
  }
`
