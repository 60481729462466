import { useCallback, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import content from 'assets/data/contents'
import { Button } from 'components/atoms/Button'
import { HeaderDiv, MobileMenu, MenuIcon, CrossIcon } from './header.styles'
import { IconButton } from 'components/atoms/IconButton'
import { useScrolling } from 'shared/hooks/useScrolling'
import NavLinks from './nav-links'
import useScreenSize from 'shared/hooks/useScreenSize'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

const Header = () => {
  const headerContent = content.header

  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const [enableScrolling, disableScrolling] = useScrolling()
  const { width, breakpoint } = useScreenSize()

  const changeNavbarColor = () => {
    setIsDarkMode(window.scrollY >= 80)
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor)
    return () => {
      window.removeEventListener('scroll', changeNavbarColor)
    }
  })

  const navigateToHome = useCallback((event, link) => {
    event.preventDefault()

    const targetElement = document.getElementById(link)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
      navigate(`/`, { replace: true })
    } else {
      navigate(`/`)
    }
  }, [])

  const navigateToContactUs = useCallback(() => {
    isMenuOpen && toggleMenu()
    navigate('#contact-us')
    document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' })
  }, [isMenuOpen, navigate])

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (isMenuOpen) {
      disableScrolling()
    } else {
      enableScrolling()
    }
  }, [disableScrolling, enableScrolling, isMenuOpen])

  return (
    <HeaderDiv
      $dark={isDarkMode}
      $menuOpen={isMenuOpen}
      className={`container-fluid shadow-sm position-sticky top-0 header`}
      id="header"
    >
      <div className="d-flex justify-content-between align-items-center py-3">
        <Link
          className="navbar-brand brand-logo fs-4"
          onClick={event => navigateToHome(event, 'home')}
        >
          <div className="d-flex align-items-center gap-2">
            <span className={`site-logo ${isDarkMode ? 'bg-white' : ''}`}>
              <Logo />
            </span>
            GRAYPACT
            {/*TODO: remove once responsive development is done*/}
            <span className={'d-none'}>
              {width} {breakpoint}
            </span>
          </div>
        </Link>

        <div className="d-none d-lg-flex flex-nowrap">
          <ul className="d-flex gap-4 m-0 p-0">
            <NavLinks navLinks={headerContent.headings} />
          </ul>
        </div>

        <IconButton className="d-lg-none" onClick={toggleMenu}>
          {!isMenuOpen ? (
            <MenuIcon $dark={isDarkMode} />
          ) : (
            <CrossIcon $dark={isDarkMode} />
          )}
        </IconButton>

        <Button
          $secondary={!isDarkMode}
          className="d-none d-lg-block"
          onClick={navigateToContactUs}
        >
          {headerContent.button.text}
        </Button>
      </div>

      <MobileMenu $open={isMenuOpen}>
        <div className="inner-wrapper">
          <ul className="d-flex flex-column align-items-center gap-3 p-0">
            <NavLinks
              navLinks={headerContent.headings}
              isMenuOpen={isMenuOpen}
              onClick={toggleMenu}
            />
          </ul>
          <Button $secondary onClick={navigateToContactUs}>
            {headerContent.button.text}
          </Button>
        </div>
      </MobileMenu>
    </HeaderDiv>
  )
}

export default Header
