import styled from 'styled-components'
import PropTypes from 'prop-types'
import { device } from 'shared/device-breakpoints'

const SectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.$bgColor || props.theme.blackWhite.white0};
  font-weight: 400;

  ${device.laptop} {
    padding: 0 100px;
  }
`

SectionWrapper.propTypes = {
  $bgColor: PropTypes.string,
}

export default SectionWrapper
