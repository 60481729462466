import GenerativeAI from 'assets/blogs/generative-ai.png'
import DataAnalytics from 'assets/blogs/data-analytics-intro.png'
import CloudComputing from 'assets/blogs/cloud-computing-growth.jpg'
import AiCyberSecurity from 'assets/blogs/ai-cyber-security.png'
import IT5G from 'assets/blogs/it-5g.jpg'

const BlogsImageComponent = ({ slug }) => {
  if (slug === 'generative-ai') {
    return <img src={GenerativeAI} alt="blog-image" />
  }
  if (slug === 'cloud-computing') {
    return <img src={CloudComputing} alt="blog-image" />
  }
  if (slug === 'data-analytics') {
    return <img src={DataAnalytics} alt="blog-image" />
  }
  if (slug === 'ai-cyber-security') {
    return <img src={AiCyberSecurity} alt="blog-image" />
  }
  if (slug === 'it-infrastructure') {
    return <img src={IT5G} alt="blog-image" />
  }
  if (slug === 'cloud-computing') {
    return <img className="w-100" src={CloudComputing} alt="blog-image" />
  }
}

export default BlogsImageComponent
