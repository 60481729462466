import styled from 'styled-components'
import BackgroundImageBase from 'assets/images/bg.svg'

import { device } from 'shared/device-breakpoints'

export const FeedbackSectionWrapper = styled.div`
  font-weight: 400;

  .slider-wrapper {
    background-image: url(${BackgroundImageBase});
    background-color: ${props => props.theme.blackWhite.white50};
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 150%;

    ${device.tablet} {
      background-size: contain;
    }
  }
  .feedback-upper-section {
    background-color: ${props => props.theme.primary.primary50};
  }

  .slick-slider {
    margin-left: 4px;
    margin-right: 4px;
    ${device.tablet} {
      margin-left: 12px;
      margin-right: 12px;
    }
    ${device.laptop} {
      margin-left: -12px;
      margin-right: -12px;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
      &::before {
        color: red;
      }
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      bottom: -45px;
      align-items: center;
      position: static;
      margin: 8px 0 0;
      .prev-btn,
      .next-btn {
        cursor: pointer;
        padding: 5px;
        svg {
          transition: filter 0.1s ease-in-out;
        }
        &:hover {
          svg {
            filter: brightness(0.1);
          }
        }
      }
      .next-btn {
        transform: rotate(180deg);
      }

      ul {
        padding: 0;
        margin: 0 30px;
        li {
          button {
            &::before {
              width: 13px;
              height: 13px;
              content: '';
              border: 2px solid ${props => props.theme.blackWhite.white400};
              border-radius: 50%;
              opacity: 1;
            }
          }
          &.slick-active {
            button {
              &::before {
                border: 2px solid ${props => props.theme.secondary.secondary500};
                background: ${props => props.theme.secondary.secondary500};
              }
            }
          }
        }
      }
    }
    .slick-slide {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
      }
    }
  }
`

export const FeedbackCardContainer = styled.div`
  padding: 24px;
  margin: 0 12px 12px 12px;

  ${device.tablet} {
    margin-bottom: 24px;
  }
  border-bottom: 4px solid ${props => props.theme.secondary.secondary200};
  .feedback-header {
    display: flex;
    flex-direction: row;

    .client-initials {
      padding: 16px;
      border-radius: 16px;
      background: ${props => props.theme.secondary.secondary500};
      color: ${props => props.theme.blackWhite.white0};
      font-size: 1.8rem;
      width: 60px;
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-size: 1.2rem;
    }
    .rating-star {
      height: 16px;
      width: 16px;
    }
    .rating-text {
      font-size: 16px;
      display: inline-block;
      padding-top: 2px;
      margin-left: 12px;
    }
    .feedback-content {
      font-size: 1rem;
    }
  }
`
