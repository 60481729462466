import { useCallback } from 'react'
import { BlogLinksContainer } from './blogs-link-component.styles'
import ScrollSpy from 'react-scrollspy-navigation'

export const BlogsInfoPanel = ({ navLinks = [] }) => {
  const handleScroll = useCallback(e => {
    const { scrollTop, clientHeight, scrollHeight, classList } = e.target

    const isTopVisible = scrollTop > 20
    const isBottomVisible = scrollTop + clientHeight < scrollHeight - 20

    if (!isTopVisible && !isBottomVisible) {
      classList.remove('border-top', 'border-bottom')
    } else {
      classList.toggle('border-top', isTopVisible)
      classList.toggle('border-bottom', isBottomVisible)
    }
  }, [])

  return (
    <BlogLinksContainer
      className="border border-0"
      id="scroll-spy-root"
      onScroll={e => handleScroll(e)}
    >
      <ScrollSpy
        behavior="smooth"
        activeClass="active"
        rootMargin="-80px 0px 0px 0px"
        offsetTop={15}
      >
        <nav className="">
          <ul className="">
            {navLinks.map(({ link, text }) => (
              <li key={link}>
                <a href={`#${link}`} className="">
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </ScrollSpy>
    </BlogLinksContainer>
  )
}
