import { ImageWrapper } from '../image-wrapper'
import GooglePlacesAPI from 'assets/technologies/integrations/googleplaceapi.png'
import Paypal from 'assets/technologies/integrations/paypal.png'
import Shopify from 'assets/technologies/integrations/shopify.png'
import Stellar from 'assets/technologies/integrations/stellar.png'
import Stripe from 'assets/technologies/integrations/stripe.png'
import Zoho from 'assets/technologies/integrations/zoho.png'

const IntegrationsImages = () => {
  return (
    <>
      <ImageWrapper image={GooglePlacesAPI} alt={'GooglePlacesAPI'} />
      <ImageWrapper image={Paypal} alt={'Paypal'} />
      <ImageWrapper image={Shopify} alt={'Shopify'} />
      <ImageWrapper image={Stellar} alt={'Stellar'} />
      <ImageWrapper image={Stripe} alt={'Stripe'} />
      <ImageWrapper image={Zoho} alt={'Zoho'} />
    </>
  )
}

export default IntegrationsImages
