import Button from 'react-bootstrap/Button'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const StButton = styled(Button)`
  width: ${props => props.width}px;
  border-radius: 5px;
  height: 42px;
  background-color: ${props => props.theme.blackWhite.white0};
  border-color: ${props => props.theme.primary.primary700};
  color: ${props => props.theme.blackWhite.black0};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  &:hover {
    background-color: ${props => props.theme.primary.primary700};
    border-color: ${props => props.theme.primary.primary700};
    color: ${props => props.theme.blackWhite.white0};
  }
  &:active {
    background-color: ${props => props.theme.primary.primary700} !important;
    border-color: ${props => props.theme.primary.primary700} !important;
    color: ${props => props.theme.blackWhite.white0} !important;
  }
  &:disabled {
    background-color: ${props => props.theme.blackWhite.black200};
    border-color: ${props => props.theme.blackWhite.black200};
  }
  
  ${({ $primary }) =>
    $primary &&
    css`
      background-color: ${props => props.theme.primary.primary500};
      border-color: ${props => props.theme.primary.primary500};
      color: ${props => props.theme.blackWhite.white0};

      &:hover {
        background-color: ${props => props.theme.primary.primary700};
        border-color: ${props => props.theme.primary.primary700};
      }
    `}

  ${({ $secondary }) =>
    $secondary &&
    css`
      background-color: ${props => props.theme.secondary.secondary500};
      border-color: ${props => props.theme.secondary.secondary500};
      color: ${props => props.theme.blackWhite.white0};

      &:hover {
        background-color: ${props => props.theme.secondary.secondary700};
        border-color: ${props => props.theme.secondary.secondary700};
      }
    `}
}
`
StButton.propTypes = {
  $primary: PropTypes.bool,
  $secondary: PropTypes.bool,
}
export { StButton as Button }
