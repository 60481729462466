import styled, { css } from 'styled-components'
import { ReactComponent as CurvedArrow } from 'assets/images/curved-arrow.svg'
import { ReactComponent as DotFrame } from 'assets/images/dot-frame.svg'
import { ReactComponent as EllipseOne } from 'assets/images/ellipse-1.svg'
import { ReactComponent as EllipseTwo } from 'assets/images/ellipse-2.svg'
import Row from 'react-bootstrap/Row'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { device } from 'shared/device-breakpoints'
import { Title as SectionTitle } from 'components/atoms/SectionTitle'
import {
  aiImageStyles,
  cloudImageStyles,
  integrationImageStyles,
  techImageStyles,
  toolsImageStyles,
} from './technology-images.styles'

export const TechnologiesSectionWrapper = styled(SectionWrapper)`
  overflow: hidden;

  .sub-heading-row {
    width: auto;

    ${device.laptop} {
      width: 100%;
    }
  }

  .sub-heading {
    font-size: 16px;
    min-height: 48px;
  }
`

export const Title = styled(SectionTitle)`
  justify-content: start;
  font-size: 32px;
`

export const ArrowImage = styled(CurvedArrow)`
  position: absolute;
  top: 70px;
  right: 210px;
  display: none;

  ${device.laptop} {
    display: unset;
    top: 50px;
    right: 2%;
    transform: scale(0.6) scaleX(0.9);
  }
  ${device.laptopL} {
    top: 50px;
    right: 10%;
    transform: scale(0.8) scaleX(0.9);
  }
  ${device.laptopXL} {
    right: 15%;
  }
`

export const BrandImagesContainer = styled.div`
  .wrapper-box {
    aspect-ratio: 16/7;
  }
  .image-wrapper {
    background-color: ${props => props.theme.blackWhite.white0};
    border: 1px solid ${props => props.theme.secondary.secondary500};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
    }

    ${techImageStyles}
    ${cloudImageStyles}
    ${aiImageStyles}
    ${integrationImageStyles}
    ${toolsImageStyles}
  }
`

export const DotFrameImage = styled(DotFrame)`
  position: absolute;
  bottom: -160px;
  z-index: -1;
  display: none;
  opacity: 0.6;

  ${device.tablet} {
    display: unset;
    right: 100px;
    bottom: -200px;
  }
  ${device.laptop} {
    left: 100px;
    bottom: -200px;
  }
`
export const EllipseOneImage = styled(EllipseOne)`
  position: absolute;
  top: 240px;
  left: 14%;
  z-index: -1;
  display: none;
  opacity: 0.6;

  ${device.laptop} {
    display: unset;
  }
`
export const EllipseTwoImage = styled(EllipseTwo)`
  position: absolute;
  top: 330px;
  left: 25%;
  z-index: -1;
  display: none;
  opacity: 0.5;

  ${device.laptop} {
    display: unset;
    transform: scale(0.6);
  }
  ${device.laptopL} {
    display: unset;
    transform: scale(0.8);
  }
  ${device.laptopXL} {
    display: unset;
    transform: scale(1);
  }
`

export const MainHeading = styled(Row)``

export const TabsContainer = styled.div`
  min-height: 300px;

  ${device.laptop} {
    min-height: auto;
  }

  .tab-content {
    margin-bottom: 8px;
    ${device.tablet} {
      margin-bottom: 32px;
    }
    ${device.laptop} {
      margin-bottom: 0;
    }
  }

  .nav-tabs {
    margin-left: -4px;
    margin-right: -4px;

    ${device.tablet} {
      justify-content: center;
    }
  }

  .nav-item {
    position: relative;
    user-select: none;

    button {
      border: none;
      width: max-content;
      color: ${props => props.theme.blackWhite.black300};
      &.active {
        font-weight: 600;
        color: ${props => props.theme.blackWhite.black400};
        border: none;
        border-bottom: 2px solid ${props => props.theme.primary.primary500};
        background: unset;
      }
    }

    a {
      font-weight: 400;
      font-size: 20px;
      padding-left: 35px;
      color: ${props => props.theme.blackWhite.black200};
      &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        left: 1px;
        top: 19px;
        border: 1px solid ${props => props.theme.blackWhite.black300};
      }

      &.active {
        font-weight: 500;
        color: ${props => props.theme.blackWhite.black200};
        background: none;
        &::before {
          background-color: ${props => props.theme.primary.primary500};
          border: none;
        }
        color: ${props => props.theme.blackWhite.black300};
      }
    }
  }
`
