import * as images from 'assets/parterns'
import { PartnersContainer, ScrollContainer } from './partners.styles'

const Scroller = ({ containerClass }) => (
  <ScrollContainer className={containerClass}>
    <img src={images.adobe} alt="adobe" />
    <img src={images.azure} alt="azure" />
    <img src={images.gcp} alt="gcp" />
    <img src={images.meta} alt="meta" />
    <img src={images.figma} alt="figma" />
    <img src={images.razorpay} alt="razorpay" />
    <img src={images.shopify} alt="shopify" />
    <img src={images.stripe} alt="stripe" />
    <img src={images.aws} alt="aws" />
    <img src={images.gitlab} alt="gitlab" />
    <img src={images.hostinger} alt="hostinger" />
    <img src={images.okta} alt="okta" />
    <img src={images.slack} alt="slack" />
    <img src={images.atlassion} alt="atlassion" />
  </ScrollContainer>
)

const Partners = () => {
  return (
    <PartnersContainer className="container-fluid">
      <Scroller containerClass={'scroller1'} />
      <Scroller containerClass={'scroller2'} />
    </PartnersContainer>
  )
}

export default Partners
