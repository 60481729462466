import { theme } from '../../shared/theme'
const content = {
  header: {
    bgColor: theme.blackWhite.white0,
    headings: [
      {
        title: 'Home',
        link: 'home',
      },
      {
        title: 'Services',
        link: 'services',
      },
      {
        title: 'Portfolio',
        link: 'portfolio',
      },
      {
        title: 'Blog',
        link: 'blogs',
      },
      {
        title: 'Technology',
        link: 'technologies',
      },
      {
        title: 'About',
        link: 'about-us',
      },
    ],
    button: {
      text: 'Contact Us',
    },
  },
  hero: {
    id: 'home',
    bgColor: theme.blackWhite.white0,
    subHeading: 'Where Vision Meets Innovation',
    heading: `Your trusted partner in exploring the digital Realm.`,
    textOne:
      'Transforming Ideas into Innovative Solutions with Elite Development Teams',
    buttonOneText: "Let's begin",
  },
  services: {
    id: 'services',
    heading: 'Our Services',
    cards: [
      {
        id: 'coding',
        heading: 'Apps & Websites',
        icon: '',
        description:
          'Build custom applications for your business, from small utilities to enterprise systems, ensuring smooth functionality across desktop, web, and mobile platforms with enhanced performance.',
      },
      {
        id: 'cloud-migration',
        heading: 'Cloud Migration',
        icon: '',
        description:
          'Our Cloud Migration service moves your digital assets and infrastructure to the cloud with minimal downtime, improved performance, cost savings, and ongoing cloud optimization support.',
      },
      {
        id: 'it-consulting',
        heading: 'IT Consulting',
        icon: '',
        description:
          'We help businesses develop comprehensive, long-term IT strategies aligned with their goals, ensuring that technology investments drive growth, innovation, and competitive advantage.',
      },
      {
        id: 'analytics',
        heading: 'Data Analytics',
        icon: '',
        description:
          'We provide tailored analytics solutions to track performance, reveal trends, and support data-driven decisions, helping businesses optimize operations and drive growth.',
      },
      {
        id: 'cloud-computing',
        heading: 'Cloud Computing',
        icon: '',
        description:
          'Set up, manage, and maintain cloud environments like AWS, Azure, Google Cloud. This includes Cloud Security, DevOps and Cloud Automation and Data Migration solutions.',
      },
      {
        id: 'gen-ai',
        heading: 'Generative AI',
        icon: '',
        description:
          'Our Gen-AI solutions harness advanced AI to automate tasks, create content, and enhance decision-making, offering secure, scalable tools for smarter operations and greater impact.',
      },
      {
        id: 'design',
        heading: 'UI/UX Design',
        icon: '',
        description:
          'Create detailed wireframes and interactive prototypes to visualize the layout, functionality, and user flow of the application, ensuring clarity and alignment before development starts.',
      },
      {
        id: 'automation',
        heading: 'Automation',
        icon: '',
        description:
          'Automate manual workflows by creating customized scripts and tools that integrate systems, reducing bottlenecks in processes such as approvals, data entry, and reporting. ',
      },
    ],
  },
  projects: {
    bgColor: theme.blackWhite.white0,
    id: 'portfolio',
    heading: 'Our Recent Projects',
    tiles: [
      {
        id: 'talkbiz',
        projectName: 'Talkbiz',
        bgColor: 'whiteSmoke',
        shortDescription: {
          text: "Talkbiz is designed to connect people effortlessly, offering a seamless, fast, and secure chat interface. Whether you're chatting with friends, collaborating with colleagues, or catching up with family, the app delivers all the features you need, including multimedia sharing, group chats,  voice messaging and much more.",
        },
        chips: ['Cloud', 'Blockchain', 'Encryption', 'Development'],
      },
      {
        id: 'mixpie',
        projectName: 'Mixpai',
        bgColor: 'bianca',
        shortDescription: {
          text: "Discover the joy of cooking with our food recipe app! Whether you're a beginner or a seasoned chef, our app brings delicious recipes right to your fingertips. Explore a variety of cuisines, follow step-by-step instructions, and create mouth-watering dishes with ease.",
        },
        chips: ['Cloud', 'Blockchain', 'Encryption', 'Development'],
      },
      {
        id: 'carspot',
        projectName: 'CarSpot',
        bgColor: 'solitude',
        shortDescription: {
          text: 'CarSpot offers a seamless and intuitive platform for users to find and rent vehicles for various needs, from daily commutes to long-term road trips. Key features include:',
          list: [
            'Instant Booking',
            'Flexible Rental Periods',
            'Real-time GPS Tracking',
          ],
        },
        chips: ['Cloud', 'Blockchain', 'Encryption', 'Development'],
      },
      {
        id: 'sonomic',
        projectName: 'Sonomic',
        bgColor: 'solitude',
        shortDescription: {
          text: 'Step into a world of music tailored just for you. Our app is designed to offer a seamless, immersive experience for music lovers. Whether you’re a fan of trending hits or deep cuts, our intuitive interface helps you find exactly what you’re looking for.',
        },
        chips: ['Cloud', 'Blockchain', 'Encryption', 'Development'],
      },
    ],
  },
  technologies: {
    id: 'technologies',
    heading: 'Offering you cutting-edge technologies',
    subHeading:
      'We have worked with all the technology combinations so that you don’t have to',
    data: [
      'Technologies',
      'Cloud Services',
      'AI Integration',
      'Integrations',
      'Tools',
    ],
  },
  blogs: {
    id: 'blogs',
    heading: 'Checkout our latest blogs',
    subHeading:
      'The latest industry news, interviews, technologies, and resources.',
    blogsList: [
      {
        image: 'generative-ai.png',
        slug: 'generative-ai',
        type: 'Artificial Intelligence',
        heading: 'Generative AI',
        title: 'Generative AI: Transforming, Business and Creativity',
        readTime: '10 mins read',
        shortDescription:
          'Let"s dive into Generative AI that goes beyond being just a technological marvel—it taps into the very heart of creativity.',
        navLinks: [
          {
            text: 'Understanding Generative AI',
            link: 'understanding-generative-ai',
          },
          {
            text: 'The Business Transformation',
            link: 'business-transformation-gen-ai',
          },
          {
            text: 'The Business Case for Generative AI Investment',
            link: 'business-case-gen-ai',
          },
          {
            text: 'Understanding GenAI’s Industry-Specific Impact',
            link: 'understanding-gen-ai-impact',
          },
          {
            text: 'Beyond Routine: GenAI in Creative and Knowledge Work',
            link: 'gen-ai-creative',
          },
          {
            text: 'Customizing GenAI for Organizational Needs',
            link: 'customizing-gen-ai-needs',
          },
          {
            text: 'Rethinking Jobs and Processes in the Age of GenAI',
            link: 'rethinking-jobs-gen-ai',
          },
          {
            text: 'Leadership and Responsibility in the GenAI Era',
            link: 'leadership-gen-ai',
          },
          {
            text: 'The Creative Renaissance: Generative AI in Art and Media',
            link: 'creative-gen-ai',
          },
          {
            text: 'Ethical Considerations and Challenges',
            link: 'ethical-gen-ai',
          },
          {
            text: 'The Future of Generative AI',
            link: 'future-gen-ai',
          },
        ],
      },
      {
        image: 'cloud-computing.png',
        slug: 'cloud-computing',
        type: 'Cloud',
        heading: 'Cloud Computing',
        title: 'Cloud Computing : Driving Digital Transformation',
        readTime: '10 mins read',
        shortDescription:
          "Let's explore the role of cloud computing in digital transformation, including its benefits, challenges, and best practices.",
        navLinks: [
          {
            text: 'What is Digital Cloud Transformation?',
            link: 'what-digital-cloud',
          },
          {
            text: 'Why is Digital Cloud Transformation Important?',
            link: 'why-digital-cloud',
          },
          {
            text: 'The Rapid Growth of Digital Cloud Transformation',
            link: 'growth-digital-cloud',
          },
          {
            text: 'The Combination of Cloud Computing and Digital Transformation',
            link: 'combination-digital-cloud',
          },
          {
            text: 'Cloud Computing: A Catalyst for Innovation',
            link: 'catalyst-digital-cloud',
          },
          {
            text: 'Best Practices for Cloud Computing in Digital Transformation',
            link: 'best-practices-digital-cloud',
          },
          {
            text: 'Conclusion',
            link: 'conclusion-digital-cloud',
          },
        ],
      },
      {
        image: 'data-analytics-intro.png',
        slug: 'data-analytics',
        type: 'Analytics',
        heading: 'Data and Analytics',
        title:
          'Data-Driven Decisions: Leveraging Data Analytics for Business Growth',
        readTime: '15 mins read',
        shortDescription:
          'How has the evolution of data analytics transformed the way businesses operate, making those numbers more powerful than ever.',
        navLinks: [
          {
            text: 'The Evolution of Data Analytics',
            link: 'evolution-data-analytics',
          },
          {
            text: 'Defining Data Analytics in a Business Context',
            link: 'defining-data-analytics',
          },
          {
            text: 'Understanding Data-Driven Decision Making',
            link: 'understand-data-analytics',
          },
          {
            text: 'Benefits of Data-Driven Decision Making',
            link: 'benefits-data-driven-decision',
          },
          {
            text: 'Risks of Ignoring Data in Decision-Making',
            link: 'risks-data-driven-decision',
          },
          {
            text: 'Driving Business Growth through Data-Driven Decision Making',
            link: 'driving-business-growth-data-driven-decision',
          },
          {
            text: 'Challenges in Implementing BI and Analytics',
            link: 'challenges-bi-analytics',
          },
          {
            text: 'Overcoming Challenges',
            link: 'overcoming-challenges-data-analytics',
          },
          {
            text: 'The Transformative Impact of Business Intelligence and Analytics',
            link: 'impact-of-data-analytics',
          },
          {
            text: 'The Role of Advanced Analytics: Machine Learning and AI',
            link: 'role-advanced-analytics',
          },
          {
            text: 'Real-Life Case Studies: Success Through Data Analytics',
            link: 'case-studies-data-analytics',
          },
          {
            text: 'Best Practices for Implementing Data Analytics',
            link: 'best-practices-data-analytics',
          },
          {
            text: 'The Future of Data Analytics',
            link: 'future-data-analytics',
          },
          {
            text: 'Conclusion',
            link: 'conclusion-data-analytics',
          },
        ],
      },

      {
        image: 'ai-cyber-security.png',
        slug: 'ai-cyber-security',
        type: 'Security',
        heading: 'AI in Cyber security',
        title: 'The Role of Artificial Intelligence in Cybersecurity',
        readTime: '12 mins read',
        shortDescription:
          'AI in cybersecurity helps identify threats and manage cyber risk levels. Explore how artificial intelligence strengthens data protection.',
        navLinks: [
          {
            text: 'Introduction to AI and Machine Learning in Cyber Security',
            link: 'introduction-ai-ml',
          },
          {
            text: 'Why should we use AI in Cybersecurity',
            link: 'why-use-ai',
          },
          {
            text: 'How AI and Machine Learning Improve Threat Detection:',
            link: 'how-ai-ml-improve',
          },
          {
            text: 'Advantages of Using AI in Cybersecurity',
            link: 'ai-advantage-using-cybersecurity',
          },
          {
            text: 'Challenges and Limitations of AI in Cyber Security',
            link: 'ai-challenges-limitations',
          },
          {
            text: 'Future Possibilities and Innovations in the Field',
            link: 'ai-future-possibilities-innovations',
          },
          {
            text: 'Conclusion',
            link: 'ai-conclusion',
          },
        ],
      },
      {
        image: 'edge-computing.png',
        slug: 'it-infrastructure',
        type: 'Internet of thing',
        heading: 'Future of IOT & 5G',
        title: 'The Future of IT Infrastructure: Edge Computing, 5G, and IoT',
        readTime: '6 mins read',
        shortDescription:
          'The digital transformation era has given rise to various technologies that have revolutionized IT infrastructure.',
        navLinks: [
          {
            text: 'Introduction',
            link: 'introduction-it',
          },
          {
            text: 'What is Edge Computing?',
            link: 'what-edge-computing',
          },
          {
            text: 'How Edge Computing Works?',
            link: 'how-edge-computing-works',
          },
          {
            text: 'Key Benefits of Edge Computing in IoT',
            link: 'key-benefit-edge-computing',
          },
          {
            text: 'The Strategic Importance of Edge Computing',
            link: 'strategic-importance-edge-computing',
          },
          {
            text: 'The Synergy of 5G and Edge Computing',
            link: 'synergy-5g-edge-computing',
          },
          {
            text: 'Promising Applications of Edge Computing in IoT',
            link: 'promising-application',
          },
          {
            text: 'Real-Life Examples of Companies Offering and Using Edge Computing',
            link: 'real-life-example-edge-computing',
          },
          {
            text: 'Companies Using Edge Computing:',
            link: 'company-using-edge-computing',
          },
          {
            text: 'So, Does Edge Computing replace Cloud Computing?',
            link: 'edge-computing-replace-cloud',
          },
          {
            text: 'Challenges and Considerations',
            link: 'challenges-and-considerations',
          },
          {
            text: 'Conclusion',
            link: 'conclusion',
          },
        ],
      },
    ],
  },
  footer: {
    quickLinks: {
      heading: 'Quick Links',
      values: [
        { id: '#portfolio', name: 'Projects' },
        { id: '#services', name: 'Services' },
        { id: '#blogs', name: 'Blogs' },
        { id: '#about-us', name: 'About Us' },
      ],
    },
    social: {
      links: [
        {
          name: 'facebook',
          link: '#facebook',
        },
        {
          name: 'instagram',
          link: '#instagram',
        },
        {
          name: 'twitter',
          link: '#twitter',
        },
        {
          name: 'linkedin',
          link: '#linkedin',
        },
      ],
    },
  },
  clientsFeedback: {
    id: 'feedback',
    heading: 'What Our Clients Say About Us',
    feedbackCards: [
      {
        name: 'BTB Bangalore',
        image: '',
        ratings: 5,
        feedback:
          'Exceptional experience working with this team! They took our vision for a web application and turned it into a seamless, functional platform that exceeded our expectations. The attention to detail and responsiveness made the process smooth, enjoyable.',
      },
      {
        name: 'IGVT',
        image: '',
        ratings: 5,
        feedback:
          'Top-notch service from start to finish. The team demonstrated great technical expertise, transforming our complex requirements into a user-friendly application. We were impressed by their commitment to meeting deadlines and their dedication to delivering the best possible product.',
      },
      {
        name: 'NextGn',
        image: '',
        ratings: 5,
        feedback:
          'Outstanding collaboration! They understood our needs right from the start and created an application that is intuitive, reliable, and scalable. Their ability to troubleshoot and provide innovative solutions made a big difference in our project’s success.',
      },
      {
        name: 'BBT',
        image: '',
        ratings: 5,
        feedback:
          'We couldn’t be happier with the web application development service provided! The team was professional, flexible, and highly skilled. They were always available to address our concerns and delivered a product that fits perfectly with our business needs.',
      },
      {
        name: 'Yaan',
        image: '',
        ratings: 5,
        feedback:
          'Excellent service delivery! We appreciated the team’s knowledge and their commitment to quality. The web application they developed is robust and efficient, exactly what we were looking for. Highly recommend their services for anyone seeking reliable IT solutions.',
      },
      {
        name: 'GGM',
        image: '',
        ratings: 4,
        feedback:
          'The project was handled with expertise and professionalism. They brought our ideas to life in a way we hadn’t imagined. From timely communication to technical support, every step of the process was managed brilliantly. A fantastic partner for web development!',
      },
    ],
  },
  contactUs: {
    id: 'contact-us',
    heading: 'Lets Get in Touch!',
    haveAQuestion:
      "Have a question or need assistance? Reach out to us via email, phone, or the contact form below. We're eager to assist you.",
    niceHearing: 'Nice hearing from you!',
    visitUsHeading: 'Visit us',
    officeAddress: 'Mathura, Uttar Pradesh, India',
    contactUsHeading: 'Contact us',
    contact: {
      email: 'hello@graypact.com',
      // phone: '+91 4242 00 5656',
    },
  },
  aboutUs: {
    id: 'about-us',
    heading: 'About our company',
    description:
      'As a forward-thinking IT company, we specialize in delivering customized software services that empower companies to streamline operations, improve efficiency, and unlock new opportunities for growth.',
    figures: [
      {
        title: 'Team Members',
        figure: '50+',
      },
      {
        title: 'Projects Delivered',
        figure: '40+',
      },
      {
        title: 'Customer Satisfaction Rate',
        figure: '98%',
      },
    ],
    buttonText: 'Know more',
    strategy: [
      {
        title: 'Vision',
        subTitle: 'Strategic Outlook',
      },
      {
        title: 'Mission',
        subTitle: 'Purpose Driven',
      },
      {
        title: 'Goal',
        subTitle: 'Success Milestone',
      },
    ],
  },
}

export default content
