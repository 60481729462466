import { Button } from 'components/atoms/Button'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { useCallback, useEffect, useState } from 'react'
import {
  HeroHeadingMain,
  HeroHeadingOne,
  HeroHeadingSecond,
  DotFrameImage,
  PolygonImage,
  CircleImage1,
  CircleImage2,
  HeaderContainer,
  HeroImage,
  HeroImageContainer,
  HeroTextContainer,
} from './hero.styles'
import content from 'assets/data/contents'
import { useNavigate } from 'react-router-dom'

const Hero = () => {
  const [headerHeight, setHeaderHeight] = useState(70)
  const navigate = useNavigate()

  useEffect(() => {
    setHeaderHeight(document.getElementById('header').offsetHeight)
  }, [])

  const { id, textOne, subHeading, buttonOneText, bgColor } = content.hero

  const navigateToServices = useCallback(() => {
    navigate('#services')
    document.getElementById('services').scrollIntoView({ behavior: 'smooth' })
  }, [navigate])

  return (
    <SectionWrapper
      id={id}
      $bgcolor={bgColor}
      className="container-fluid position-relative overflow-hidden"
    >
      <HeaderContainer height={headerHeight}>
        <HeroTextContainer lg={6}>
          <HeroHeadingOne className="pb-3">{subHeading}</HeroHeadingOne>
          <HeroHeadingMain className="fw-regular">
            <span className="fw-lighter">Your&nbsp;</span>
            <span className="fw-normal">trusted partner&nbsp;</span>
            <span className="fw-lighter">in exploring the digital&nbsp;</span>
            <span className="fw-medium">Realm</span>
          </HeroHeadingMain>
          <HeroHeadingSecond className="fw-light">{textOne}</HeroHeadingSecond>
          <Button width="170" $secondary onClick={navigateToServices}>
            {buttonOneText}
          </Button>
        </HeroTextContainer>
        <HeroImageContainer lg={6}>
          <HeroImage alt="Hero" />
        </HeroImageContainer>
      </HeaderContainer>

      <DotFrameImage />
      <PolygonImage />
      <CircleImage1 />
      <CircleImage2 />
    </SectionWrapper>
  )
}

export default Hero
