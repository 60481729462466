import { device } from 'shared/device-breakpoints'
import styled from 'styled-components'

const StyledTextSection = styled.p`
  margin-top: 28px;
  font-size: 1.1rem;
`

export const TextSection = ({ children }) => {
  return <StyledTextSection>{children}</StyledTextSection>
}

export const Section = ({ id, children }) => {
  return (
    <section id={id} className="blog-section-with-sub-heading">
      {children}
    </section>
  )
}
export const SubHeading = ({ children }) => (
  <h4 className="sub-heading">{children}</h4>
)

export const OrderedList = styled.ol`
  margin-top: 20px;
  li {
    margin-bottom: 8px;
    font-size: 1.1rem;
    padding-left
  }
`

const StyledImageComponent = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  ${device.tablet} {
    height: 350px;
  }

  ${device.laptop} {
    height: 400px;
  }

  ${device.laptopXL} {
    height: 500px;
  }

  .image-container {
    position: absolute;
    top: -11px;
    left: -10px;
    width: 110%;
    height: 110%;
    background-image: url(${props => props.imagSrc});
    background-position: center top;
    background-size: cover;
    filter: blur(10px);
    z-index: 1;
    ${device.laptopXL} {
      filter: blur(15px);
    }
  }

  img {
    position: relative;
    z-index: 2; /* Places img above the blurred background */
    max-width: 85%;
    height: 90%;
    object-fit: cover;
    border: 3px solid #fff;
    border-radius: 10px;

    ${device.tablet} {
      border: 5px solid #fff;
    }
  }
`

export const ImageComponent = ({ imagSrc, altText }) => {
  return (
    <StyledImageComponent imagSrc={imagSrc}>
      <div className="image-container"></div>
      <img src={imagSrc} alt={altText} />
    </StyledImageComponent>
  )
}
