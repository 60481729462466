import styled, { css, keyframes } from 'styled-components'
import { withOpacity } from 'shared/utils'

const hrScroll1 = keyframes`
    from { transform: translateX(0%); }
    to { transform: translateX(-100%); }
`
const hrScroll2 = keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0%); }
`

export const PartnersContainer = styled.div`
  width: 100%;
  height: 60px;
  border-top: 0.5px solid
    ${props => withOpacity(props.theme.blackWhite.black200, 60)};
  background-color: ${props => props.theme.blackWhite.white0};
  position: relative;
  overflow: hidden;
`

const images = css`
  img {
    height: 60px;
    width: auto;
  }
  img[alt='adobe'] {
    height: 50px;
    margin-right: 50px;
  }
  img[alt='aws'] {
    height: 40px;
    margin-right: 44px;
  }
  img[alt='azure'] {
    height: 45px;
    margin-right: 32px;
  }
  img[alt='figma'] {
    height: 20px;
    margin-right: 40px;
  }
  img[alt='gcp'] {
    height: 120px;
    margin-right: 36px;
  }
  img[alt='meta'] {
    height: 50px;
    margin-right: 60px;
  }
  img[alt='razorpay'] {
    height: 50px;
    margin-right: 32px;
  }
  img[alt='shopify'] {
    height: 70px;
    margin-right: 32px;
  }
  img[alt='stripe'] {
    height: 50px;
    margin-right: 40px;
  }
  img[alt='hostinger'] {
    height: 90px;
    margin-right: 28px;
  }
  img[alt='gitlab'] {
    height: 25px;
    margin-right: 30px;
  }
  img[alt='okta'] {
    height: 50px;
    margin-right: 42px;
  }
  img[alt='slack'] {
    height: 50px;
    margin-right: 46px;
  }
  img[alt='atlassion'] {
    height: 20px;
    margin-right: 46px;
  }
`

export const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  white-space: nowrap;

  animation-duration: 50s;
  animation-delay: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;

  &:hover {
    animation-play-state: paused;
  }

  &.scroller1 {
    animation-name: ${hrScroll1};
    &:hover {
      & + .scroller2 {
        animation-play-state: paused;
      }
    }
  }
  &.scroller2 {
    animation-name: ${hrScroll2};
    &:hover {
      .scroller1:has(+ &) {
        animation-play-state: paused;
      }
    }
  }

  ${images}
`
