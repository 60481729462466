import { ReactComponent as StarFilled } from 'assets/images/star-filled.svg'
import { ReactComponent as StarOutlined } from 'assets/images/star-outlined.svg'

const StarRating = ({ rating, maxStars = 5 }) => {
  const stars = Array.from({ length: maxStars }, (_, index) =>
    index < rating ? (
      <StarFilled key={index} className="me-1 rating-star" />
    ) : (
      <StarOutlined key={index} className="me-1 rating-star" />
    )
  )

  return (
    <div className={'d-flex align-items-center'}>
      {stars} <span className={'rating-text'}>{rating.toFixed(1)}</span>
    </div>
  )
}

export default StarRating
