import content from 'assets/data/contents'
import { BlogsSectionWrapper, SubTitle } from './blogs.styles'
import SectionWrapper from 'components/atoms/SectionWrapper'
import { DecoLine } from 'components/atoms/DecoLine'
import { Row } from 'react-bootstrap'
import SimpleSlider from './carousel'
import NavigationAnchor from 'components/atoms/navigation-anchor'
import { Title } from 'components/atoms/SectionTitle'

const Blogs = () => {
  const { id, heading, subHeading } = content.blogs

  return (
    <BlogsSectionWrapper className="">
      <SectionWrapper className="blogs-upper-section">
        <NavigationAnchor id={id} />
        <Row className={'justify-content-center mt-5'}>
          <DecoLine />
        </Row>
        <Title className="text-center py-3 pb-2 px-5">{heading}</Title>
        <SubTitle className="text-center pb-4">{subHeading}</SubTitle>
      </SectionWrapper>
      <div className="position-relative slider-wrapper">
        <SectionWrapper className="blogs-lower-section pb-5">
          <SimpleSlider />
        </SectionWrapper>
      </div>
    </BlogsSectionWrapper>
  )
}

export default Blogs
