import React, { useEffect } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import FeedbackCard from './feedback-card'
import { useRef } from 'react'
import { ReactComponent as Arrow } from 'assets/images/arrow.svg'
import content from 'assets/data/contents'
import { deviceWidth } from '../../shared/device-breakpoints'

const FeedbackSlider = () => {
  const { feedbackCards } = content.clientsFeedback
  const sliderRef = useRef(null)

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }, [sliderRef])

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesPerRow: 1,
    rows: 2,
    autoplay: true,
    autoplaySpeed: 400000,
    cssEase: 'linear',
    pauseOnHover: true,
    appendDots: dots => (
      <div className="d-flex">
        <span className="prev-btn" onClick={prevSlide}>
          <Arrow />
        </span>
        <ul> {dots} </ul>
        <span className="next-btn" onClick={nextSlide}>
          <Arrow />
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: deviceWidth.xl,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: deviceWidth.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Slider ref={sliderRef} {...settings}>
      {feedbackCards.map((card, i) => (
        <FeedbackCard feedback={card} key={i} />
      ))}
    </Slider>
  )
}

export default FeedbackSlider
