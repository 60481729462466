import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BlogCard from './blog-card'
import { useRef } from 'react'
import { ReactComponent as Arrow } from 'assets/images/arrow.svg'
import content from 'assets/data/contents'
import { StyledBlogsCarousel } from './blogs.styles'

const SimpleSlider = () => {
  const { blogsList } = content.blogs
  const sliderRef = useRef(null)

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    appendDots: dots => (
      <div className="d-flex">
        <span className="prev-btn" onClick={prevSlide}>
          <Arrow />
        </span>
        <ul> {dots} </ul>
        <span className="next-btn" onClick={nextSlide}>
          <Arrow />
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <StyledBlogsCarousel>
      <Slider ref={sliderRef} {...settings}>
        {blogsList.map((card, i) => (
          <BlogCard blogData={card} key={i} />
        ))}
      </Slider>
    </StyledBlogsCarousel>
  )
}

export default SimpleSlider
