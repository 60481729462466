import styled, { css } from 'styled-components'
import { device } from 'shared/device-breakpoints'
import { withOpacity } from '../../shared/utils'
import { MenuIconBase, CrossIconBase } from 'assets/images'

export const Link = styled.a`
  font-weight: 400;
  font-style: normal;
  color: ${props => props.theme.blackWhite.black0};
  letter-spacing: 1px;
  text-decoration: none;
`

export const HeaderDiv = styled.div`
  padding: 0 16px;
  transition: all 0.4s ease-in-out;
  background-color: ${props => props.theme.blackWhite.white0};
  z-index: 1000;

  .brand-logo, .nav-link {
    color: ${props => props.theme.blackWhite.black0};
  }

  .brand-logo {
    .site-logo {
      border-radius: 6px;
      height: 38px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;

      svg {
        max-height: 100%;
      }
    }
  }

  ${device.tablet} {
    padding: 0 24px;
  }

  ${device.laptop} {
    padding: 0 100px;
  }

  ${({ $dark }) =>
    $dark &&
    css`
      background-color: ${props => props.theme.primary.primary500};

      .brand-logo,
      .nav-link {
        color: ${props => props.theme.blackWhite.white0};
      }
    `}
}
`

export const List = styled.li`
  list-style: none;
`

export const MobileMenu = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70px;
  left: 0;
  width: 100%;
  height: ${props => (props.$open ? '110vh' : '0')};
  background-color: ${props =>
    withOpacity(props.theme.blackWhite.white200, 70)};
  opacity: ${props => (props.$open ? 1 : 0)};
  transition: opacity 0.2s ease;
  overflow: hidden;

  .inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.blackWhite.white0};
    padding: 60px;
    border-top: 1px solid
      ${props => withOpacity(props.theme.primary.primary500, 20)};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .mobile-nav-link {
    display: inline-block;
    color: ${props => props.theme.blackWhite.black0};
    line-height: 18px;
    border-bottom: 1px solid ${props => props.theme.blackWhite.black0};
  }
`

MobileMenu.displayName = 'MobileMenu'

export const MenuIcon = styled(MenuIconBase)`
  fill: ${props => props.theme.blackWhite.white0};
  ${({ $dark }) =>
    $dark &&
    css`
      path {
        stroke: ${props => props.theme.blackWhite.white0};
      }
    `}
`
export const CrossIcon = styled(CrossIconBase)`
  fill: ${props => props.theme.blackWhite.white0};
  ${({ $dark }) =>
    $dark &&
    css`
      path {
        stroke: ${props => props.theme.blackWhite.white0};
        fill: ${props => props.theme.blackWhite.white0};
      }
    `}
`
