import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'

import { ReactComponent as DotFrame } from 'assets/images/dot-frame.svg'
import { ReactComponent as Polygon } from 'assets/images/polygonp-1.svg'
import { ReactComponent as Circles } from 'assets/images/circles.svg'
import { ReactComponent as HeroImg } from 'assets/images/hero-image.svg'
import { device } from 'shared/device-breakpoints'

export const HeroImageContainer = styled(Col)`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  ${device.tablet} {
    height: 600px;
  }
  ${device.laptop} {
    height: auto;
  }
`

export const HeroImage = styled(HeroImg)`
  object-fit: contain;
  width: 100%;

  ${device.tablet} {
    margin-top: -40px;
  }
  ${device.laptop} {
    min-width: 500px;
    max-width: 600px;
  }
`

export const HeroTextContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  ${device.tablet} {
    margin-top: -40px;
  }
  ${device.laptop} {
    align-items: flex-start;
  }
  ${device.laptopXL} {
    button {
      margin-top: 40px;
    }
  }
`

export const HeroHeadingOne = styled.div`
  color: ${props => props.theme.primary.primary500};
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  text-align: center;

  ${device.laptop} {
    margin-top: 100px;
    text-align: left;
  }
  ${device.laptopL} {
    margin-top: 130px;
  }
`

export const HeroHeadingMain = styled.div`
  font-size: 2.2rem;
  line-height: 3rem;
  color: ${props => props.theme.blackWhite.black0};
  font-style: normal;
  text-align: center;
  padding: 0 12px 36px 12px;

  ${device.tablet} {
    font-size: 3.15rem;
    line-height: 4.5rem;
  }

  ${device.laptop} {
    text-align: left;
    padding: 0 0 48px 0;
  }
`

export const HeroHeadingSecond = styled.div`
  color: ${props => props.theme.blackWhite.black0};
  font-style: normal;
  text-align: center;
  font-size: 1em;
  padding-bottom: 2rem;

  ${device.tablet} {
    padding-bottom: 3rem;
    font-size: 1.2em;
  }
  ${device.laptop} {
    text-align: left;
  }
`

export const CircleImage1 = styled(Circles)`
  position: absolute;
  left: -180px;
  width: 240px;
  display: none;
  ${device.laptopL} {
    display: unset;
    bottom: 200px;
  }
`
export const CircleImage2 = styled(Circles)`
  position: absolute;
  width: 365px;
  bottom: -162px;
  right: 20px;
  display: none;
`

export const DotFrameImage = styled(DotFrame)`
  position: absolute;
  width: 312px;
  bottom: -192px;
  right: 361px;
  margin: auto;
  display: none;
  opacity: 0.6;
  ${device.tablet} {
    display: unset;
    bottom: -210px;
    left: -150px;
  }
  ${device.laptopL} {
    left: 0;
  }
`

export const PolygonImage = styled(Polygon)`
  position: absolute;
  width: 250px;
  left: 600px;
  display: none;
  ${device.tablet} {
    display: unset;
    bottom: 320px;
  }
  ${device.laptopL} {
    top: 100px;
  }
`
export const HeaderContainer = styled(Row)`
  flex-direction: column-reverse;

  ${device.laptop} {
    height: calc(100vh - ${props => props.height}px);
    overflow: hidden;
    flex-direction: row;
  }
`
