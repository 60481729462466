import content from 'assets/data/contents'
import Nav from 'react-bootstrap/Nav'

const TabsList = ({ activeKey, setActiveKey }) => {
  const handleMouseEnter = heading => {
    setActiveKey(heading)
  }
  const { data } = content.technologies

  return (
    <>
      {data.map(heading => {
        return (
          <Nav.Item key={heading}>
            <Nav.Link eventKey={heading} active={activeKey === heading}>
              <span onMouseEnter={() => handleMouseEnter(heading)}>
                {heading}
              </span>
            </Nav.Link>
          </Nav.Item>
        )
      })}
    </>
  )
}

export default TabsList
