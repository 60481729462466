import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FeedbackCardContainer } from './feedback.styles'
import StarRating from './star-ratings'
import { Row, Col } from 'react-bootstrap'

const FeedbackCard = ({ feedback }) => {
  const [initials, setInitials] = useState('')

  useEffect(() => {
    const temp = feedback.name
      .split(' ')
      .map(word => word[0])
      .join('')

    setInitials(temp || temp[0])
  }, [feedback.name, initials])

  return (
    <FeedbackCardContainer className="feedback-card bg-white shadow">
      <div className="feedback-header">
        <div className="client-initials">{initials}</div>
        <div className="d-inline-flex flex-column ms-3">
          <div className="title">{feedback.name}</div>
          <StarRating rating={feedback.ratings} />
        </div>
      </div>
      <div className={'mt-2 mt-md-3'}>{feedback.feedback}</div>
    </FeedbackCardContainer>
  )
}

export default FeedbackCard

FeedbackCard.propTypes = {
  feedback: PropTypes.object,
}
