import Hero from 'sections/hero/index'
import Services from 'sections/services'
import Projects from 'sections/projects'
import Technologies from 'sections/technologies'
import Blogs from 'sections/blogs'
import AboutUs from 'sections/about-us'
import Partners from 'sections/partners'
import ContactUs from 'sections/contact-us'
import ClientsFeedback from 'sections/clients-feedback'

function Home() {
  return (
    <div>
      <Hero />
      <Services />
      <Projects />
      <Blogs />
      <Technologies />
      <AboutUs />
      <Partners />
      <ClientsFeedback />
      <ContactUs />
    </div>
  )
}

export default Home
