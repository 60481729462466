export const deviceWidth = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 2560,
}

export const device = {
  mobileS: `@media (min-width: ${deviceWidth.xs}px)`,
  mobile: `@media (min-width: ${deviceWidth.sm}px)`,
  tablet: `@media (min-width: ${deviceWidth.md}px)`,
  laptop: `@media (min-width: ${deviceWidth.lg}px)`,
  laptopL: `@media (min-width: ${deviceWidth.xl}px)`,
  laptopXL: `@media (min-width: ${deviceWidth.xxl}px)`,
  monitor: `@media (min-width: ${deviceWidth.xxxl}px)`,
}

export const getBreakPoint = width => {
  if (width >= deviceWidth.xxxl) {
    return `monitor:xxxl`
  }
  if (width >= deviceWidth.xxl) {
    return `laptopXL:xxl`
  }
  if (width >= deviceWidth.xl) {
    return `laptopL:xl`
  }
  if (width >= deviceWidth.lg) {
    return `laptop:lg`
  }
  if (width >= deviceWidth.md) {
    return `tablet:md`
  }
  if (width >= deviceWidth.sm) {
    return `mobile:sm`
  }
}
